import { useEffect, useRef } from 'react'

export function HtmlEmbed({ blok }) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!blok.html || !divRef.current) throw "html prop cant't be null"

    const slotHtml = document.createRange().createContextualFragment(blok.html) // Create a 'tiny' document and parse the html string
    
    
    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml) // Append the new content
  }, [blok.html, divRef])

  return <div ref={divRef}></div>
}

export default HtmlEmbed