import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import RichText from "./RichText"

const FaqAccordion = ({ blok }) => {
  const richTextBlok = {
    content: blok.faq.content?.answer,
    alignment: 'left',
    textColor: blok.textColor === 'light' ? 'white' : 'brand.darkgray',
    textSaturation: '600',
    textShadow: 0
  }

  return (
    <Accordion allowToggle {...storyblokEditable(blok)} w="full">
      <AccordionItem>
        <AccordionButton
          _hover={{
            backgroundColor: 'whiteAlpha.300'
          }}
        >
          <Box flex='1' textAlign='left' marginY="2">
            <Text
              as="h4"
              fontSize={'sm'}
              textTransform="uppercase"
              textColor={blok.textColor === 'light' ? 'white' : 'brand.darkgray.600'}
              fontWeight="bold"
            >
              {blok.faq.content?.question}
            </Text>
          </Box>
          <AccordionIcon textColor={blok.textColor === 'light' ? 'white' : 'brand.darkgray.600'} />
        </AccordionButton>
        <AccordionPanel pb={4}>
          <RichText blok={richTextBlok} />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
};

export default FaqAccordion;
