import { Flex } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import { convertColor } from "../../utils/misc"
import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent"


const Row = ({ blok, globalState, setGlobalState, locationState , ppm365, locale}: DynamicComponentProps<any>):JSX.Element => {

    const horizontalAlign = blok.alignment === 'center' ? 'center' : blok.alignment === 'left' ? 'flex-start' : 'flex-end';
    const verticalAlign = blok.verticalAlignment === 'middle' ? 'center' : blok.verticalAlignment === 'top' ? 'flex-start' : 'flex-end';
    const bgColor = convertColor(blok.backgroundColor, blok.backgroundSaturation) 
    const borderColor = convertColor(blok.borderColor, blok.borderSaturation)
    return (
        <Flex
        {...storyblokEditable(blok)}
        border={`solid`}
        borderWidth={blok.borderWidth ? blok.borderWidth : 0}
        borderColor={borderColor}
        borderRadius={blok.borderRadius}
        shadow={blok.shadow}
        w={blok.width ? blok.width : 'full'}
        maxW={blok.maxWidth}
        bgColor={bgColor}
        flexDirection="row"
        gap={5}
        mt={blok.marginTop}
        mb={blok.marginBottom}
        px={blok.paddingX}
        py={blok.paddingY}
        alignItems={verticalAlign}
        justifyContent={horizontalAlign}
        overflow="hidden"
      >
        {blok.anchor && <a id={blok.anchor} style={{position: 'relative', top: '-40px'}}></a>}
        {
          blok.content?.map((nestedBlok, index) => (
            <DynamicComponent 
            blok={nestedBlok} 
            key={index} 
            globalState={globalState} 
            setGlobalState={setGlobalState} 
            locationState={locationState}
            ppm365={ppm365}
            locale={locale}
          />
          ))
        }
      </Flex>
    )
}

export default Row