import { storyblokEditable } from "@storyblok/react";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Fancybox from "../misc/Fancybox";

interface LocationVideoButtonProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
}

const LocationVideoButton = ({ blok, globalState, locationState }:LocationVideoButtonProps):JSX.Element => {
  return (
    locationState?.video && 
    <Fancybox>
      <Button
        colorScheme="brand.green"
        leftIcon={<FontAwesomeIcon type="fas" icon="video" />}
        data-fancybox="video-gallery"
        data-src={locationState?.video}
        {...storyblokEditable(blok)}
      >
        Watch the Video
      </Button>
    </Fancybox>
  );
}

export default LocationVideoButton;
