import PodcastPlayerLarge from "./misc/PodcastPlayerLarge";
import Grid from "./layout/Grid";
import Placeholder from "./layout/Placeholder";
import Page from "./layout/Page";
import Text from "./layout/Text";
import LocationLandingPage from "./locations/LocationLandingPage";
import LocationAtAGlance from "./locations/LocationAtAGlance";
import TripJournalEntryList from "./journals/TripJournalEntryList";
import TripJournalHero from "./journals/TripJournalHero";
import RichText from "./layout/RichText";
import ParallaxHero from "./layout/hero/ParallaxHero";
import Container from "./layout/Container";
import TestimonialCard from "./misc/TestimonialCard";
import TwoColumns from "./layout/TwoColumns";
import TwoToneText from "./layout/TwoToneText";
import LocationCards from "./locations/LocationCards";
import ImageLink from "./layout/ImageLink";
import Spacer from "./layout/Spacer";
import ContactForm from "./misc/contact-form/ContactForm";
import MissionTripSearch from "./mission-trip-dates/MissionTripSearch";
import Box from "./layout/Box";
import HeroBackgroundImage from "./layout/hero/HeroBackgroundImage";
import ButtonLink from "./buttons/ButtonLink";
import MinistryOptionCard from "./layout/MinistryOption";
import SampleItinerary from "./layout/SampleItinerary";
import FaqAccordion from "./layout/FaqAccordion";
import LocationCommunityMap from "./locations/LocationCommunityMap";
import LocationFaqs from "./locations/LocationFaqs";
import LocationFeaturedJournals from "./locations/LocationFeaturedJournals";
import LocationInfo from "./locations/LocationInfo";
import LocationMainContent from "./locations/LocationMainContent";
import LocationTripPricing from "./locations/LocationTripPricing";
import LocationTripSearch from "./locations/LocationTripSearch";
import LocationVideoButton from "./locations/LocationVideoButton";
import ImageBlok from "./layout/Image";
import HeroSideImage from "./layout/hero/HeroSideImage";
import ButtonVideo from "./buttons/ButtonVideo";
import ButtonModal from "./buttons/ButtonModal";
import ResponsiveVideoEmbed from "./layout/ResponsiveVideoEmbed";
import PersonalBio from "./layout/PersonalBio";
import LocationMap from "./layout/LocationMap";
import CircleIcon from "./layout/CircleIcon";
import Line from "./layout/Line";
import DisasterResponseList from "./disaster/DisasterResponseList";
import DisasterResponseContent from "./disaster/DisasterResponseContent";
import DisasterResponseHero from "./disaster/DisasterResponseHero";
import DisasterResponseSubscribe from "./disaster/DisasterResponseSubscribe";
import DisasterResponseUpdates from "./disaster/DisasterResponseUpdates";
import FundraisingShirt from "./misc/FundraisingShirt";
import TripJournalSubscribe from "./journals/TripJournalSubscribe";
import AddThis from "./misc/AddThis";
import TripJournalComments from "./journals/TripJournalComments";
import HtmlEmbed from "./misc/HtmlEmbed";
import ImageCardLink from "./layout/ImageCardLink";
import TripDateList from "./layout/TripDateList";
import HomeParticipantsServing from "./misc/HomeParticipantsServing";
import Location365Updates from "./365/Location365Updates";
import Location365Subscribe from "./365/Location365Subscribe";
import Ppm365LocationCards from "./365/Ppm365LocationCards";
import MainPpm365Subscribe from "./365/MainPpm365Subscribe";
import MainPpm365Updates from "./365/MainPpm365Updates";
import TripPricingTable from "./layout/TripPricingTable";
import TwoLineText from "./layout/TwoLineText";
import DisasterResponseDates from "./disaster/DisasterResponseDates";
import LocationTravelAdvisory from "./locations/LocationTravelAdvisory";
import ButtonFeaturedDisaster from "./buttons/ButtonFeaturedDisaster";
import ChurchChampionForm from "./misc/church-champion-form/ChurchChampionForm";
import PartnerPhoto from "./365/partners/PartnerPhoto";
import PartnerText from "./365/partners/PartnerText";
import Row from "./layout/Row";
import PartnerMap from "./365/partners/PartnerMap";
import PartnerLandingMap from "./365/partners/PartnerLandingMap";
import PartnerList from "./365/partners/PartnerList";
import PartnerRichText from "./365/partners/partnerRichText";
import PartnerFundedContainer from "./365/partners/partnerFundedContainer";
import PartnerSupporterUpdates from "./365/partners/partnerSupporterUpdates";
import PartnerSupportForm from "./365/partners/partnerSupportForm";
import PartnerSupportBox from "./365/partners/partnerSupportBox";
import PartnerCarousel from "./365/partners/partnerCarousel";
import SbSignInButton from "./misc/sbSignInButton";
import DisasterResponseMap from "./disaster/DisasterResponseMap";
import DisasterResponseCards from "./disaster/DisasterResponseCards";
import DonationForm from "./stripe/DonationForm";
import RegisterReadinessTripForm from "./disaster-response/registerReadinessTripForm";
import ReadinessTripList from "./disaster-response/readinessTripList";
import LocationCardsV2 from "./locations/LocationCardsV2";

const Components = {
  grid: Grid,
  page: Page,
  text: Text,
  twoToneText: TwoToneText,
  locationCards: LocationCards,
  locationCardsV2: LocationCardsV2,
  heroSideImage: HeroSideImage,
  tripJournalEntryList: TripJournalEntryList,
  tripJournalHero: TripJournalHero,
  tripJournalSubscribe: TripJournalSubscribe,
  tripJournalComments: TripJournalComments,
  twoColumns: TwoColumns,
  richText: RichText,
  parallaxHero: ParallaxHero,
  container: Container,
  testimonialCard: TestimonialCard,
  imageLink: ImageLink,
  image: ImageBlok,
  spacer: Spacer,
  contactForm: ContactForm,
  missionTripSearch: MissionTripSearch,
  box: Box,
  heroBackgroundImage: HeroBackgroundImage,
  buttonLink: ButtonLink,
  ministryOptionCard: MinistryOptionCard,
  sampleItinerary: SampleItinerary,
  faqAccordion: FaqAccordion,
  locationAtAGlance: LocationAtAGlance,
  locationCommunityMap: LocationCommunityMap,
  locationFaqs: LocationFaqs,
  locationFeaturedJournals: LocationFeaturedJournals,
  locationInfo: LocationInfo,
  locationLandingPage: LocationLandingPage,
  locationMainContent: LocationMainContent,
  locationTripPricing: LocationTripPricing,
  locationTripSearch: LocationTripSearch,
  locationVideoButton: LocationVideoButton,
  buttonVideo: ButtonVideo,
  buttonModal: ButtonModal,
  responsiveVideoEmbed: ResponsiveVideoEmbed,
  personalBio: PersonalBio,
  podcastPlayerLarge: PodcastPlayerLarge,
  locationMap: LocationMap,
  circleIcon: CircleIcon,
  line: Line,
  disasterResponseList: DisasterResponseList,
  disasterResponseContent: DisasterResponseContent,
  disasterResponseHero: DisasterResponseHero,
  disasterResponseSubscribe: DisasterResponseSubscribe,
  disasterResponseUpdates: DisasterResponseUpdates,
  disasterResponseDates: DisasterResponseDates,
  fundraisingShirt: FundraisingShirt,
  addThis: AddThis,
  htmlEmbed: HtmlEmbed,
  imageCardLink: ImageCardLink,
  tripDateList: TripDateList,
  homeParticipantsServing: HomeParticipantsServing,
  location365Updates: Location365Updates,
  location365Subscribe: Location365Subscribe,
  ppm365LocationCards: Ppm365LocationCards,
  mainPpm365Subscribe: MainPpm365Subscribe,
  mainPpm365Updates: MainPpm365Updates,
  tripPricingTable: TripPricingTable,
  twoLineText: TwoLineText,
  locationTravelAdvisory: LocationTravelAdvisory,
  buttonFeaturedDisaster: ButtonFeaturedDisaster,
  churchChampionForm: ChurchChampionForm,
  partnerPhoto: PartnerPhoto,
  partnerText: PartnerText,
  row: Row,
  partnerMap: PartnerMap,
  partnerLandingMap: PartnerLandingMap,
  partnerList: PartnerList,
  partnerRichText: PartnerRichText,
  partnerFundedContainer: PartnerFundedContainer,
  partnerSupporterUpdates: PartnerSupporterUpdates,
  partnerSupportForm: PartnerSupportForm,
  partnerSupportBox: PartnerSupportBox,
  partnerCarousel: PartnerCarousel,
  sbSignInButton: SbSignInButton,
  disasterResponseMap: DisasterResponseMap,
  disasterResponseCards: DisasterResponseCards,
  donationForm: DonationForm,
  readinessTripRegistrationForm: RegisterReadinessTripForm,
  readinessTripList: ReadinessTripList
};

export interface DynamicComponentProps<T = any> {
  blok: T;
  globalState: GlobalState;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
  locale: string,
  locationState?: RootsLocation;
  ppm365?: {location: RootsLocation, updates: Ppm365Update[]};
}

export interface DynamicComponentPropsWithChildren<T = any> extends DynamicComponentProps<T> {
  children?: React.ReactNode
}

const DynamicComponent = ({ blok, globalState, setGlobalState, locale, locationState, ppm365 }: DynamicComponentProps):JSX.Element => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return <Component 
      blok={blok} 
      globalState={globalState} 
      locale={locale}
      setGlobalState={setGlobalState} 
      locationState={locationState}
      ppm365={ppm365}
    />;
  }
  return <Placeholder componentName={blok.component} />;
};

export default DynamicComponent;
