import Decimal from "decimal.js";
import { Stripe, loadStripe, StripeAddressElementChangeEvent } from '@stripe/stripe-js'

let stripePromise: Promise<Stripe | null>
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)
  }

  return stripePromise
}

export const getPaymentIntent = async (amount, metadata) => {
  if (metadata.participantID === null) {
    delete metadata.participantID;
  }

  const getIntent = await (await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/stripe/payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      amount: amount,
      metadata: metadata,
      test: 0,
      description: 'Donation to Praying Pelican Missions'
    })
  })).json()

  return getIntent;
}

export const getPaymentIntentV2 = async(amount: number, description: string, metadata?: any, customFieldValues?: any): Promise<{clientSecret: string}> => {
  const result = await (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/stripe/create-donation-payment-intent`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({amount: amount, description: description, metadata:metadata, customFields: customFieldValues})
  })).json()

  if (result.error) {
    throw result.error
  }
  else {
    return result
  }
}

export const getRecurringPaymentIntent = async(amount: number, description: string, email: string, addressElement: StripeAddressElementChangeEvent["value"], metadata?: any, customFieldValues?: any): Promise<{clientSecret: string}> => {
  const result = await (await fetch(`${process.env.NEXT_PUBLIC_LOOPBACK_URL}/stripe/create-recurring-donation`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({amount: amount, customerDetails: {email: email, address: addressElement}, description: description, metadata:metadata, customFields: customFieldValues})
  })).json()
  
  if (result.error) {
    throw result.error
  }
  else {
    return result
  }
  
}

/**
 * Calculates how much to add to a donation to allow receipt of the full donation amount
 * @param donationAmount donation amount in cents
 * @returns Fee amount to add to receive full donation amount
 */
export const feeAmountCoverage = (donationAmount: number) => {
  if (Number.isNaN(donationAmount)) return 0

  const donationDecimal = new Decimal(donationAmount).dividedBy(100)

  // y = donation amount  x = total charge

  // x - (x*.022 + .30) = y

  // x - .022x - .30 = y

  // x - .022x = y + .30

  // x(1 - .022) = y + .30

  // x = (y + .30) / (1-.022)

  const newTotal = (donationDecimal.plus(new Decimal(.30))).dividedBy((1 - .022))

  return newTotal.minus(donationDecimal).times(100).round().toNumber()
}
