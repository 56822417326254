import { Box, Button, Flex, GridItem, HStack, LinkBox, LinkOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid, Stack, Tag, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import Link from "next/link";
import React, { useEffect, useMemo, useState } from "react";
import { filterLocations } from "../../utils/locationHooks";
import { shuffleArray } from "../../utils/misc";
import { Card } from "../layout/Card";
import { Cards } from "../layout/Cards";
import { CardV2 } from "../layout/CardV2";
import { LocationLink } from "./LocationLink"

const LocationCardsV2 = ({ blok, globalState }) => {
    const [locationFilter, setLocationFilter] = useState<string>('All Locations');
    



    const handleChangeLocationFilter = (filterValue: string) => {
        setLocationFilter(filterValue);
        setFilteredLocations(shuffleArray(filterLocations(filterValue, locations)))

    }

    const locations = globalState.locations.filter(location => {
        if (blok.regionFilter === 'All' || !blok.regionFilter) return true;
        if (blok.regionFilter === location.region) return true;
        if (blok.regionFilter === 'International' && location.rootsData.domestic === 0) return true;
        if (blok.regionFilter === 'Domestic' && location.rootsData.domestic === 1) return true;

        return false;
    });


    const [filteredLocations, setFilteredLocations] = useState(filterLocations(locationFilter, locations))


    useEffect(() => {
        setFilteredLocations(shuffleArray(filteredLocations))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const regionArray = useMemo(() => {
        let regions: string[] = locations.map(location => location.region).sort((a, b) => a.localeCompare(b));

        regions = Array.from(new Set(regions))

        return sortRegions(regions)

    }, [locations])



    const FilterButton = ({ filterString, onClose }) => {
        return (

            <Button
                size="sm"
                variant={filterString === locationFilter ? 'solid' : 'outline'}
                colorScheme="brand.green"
                onClick={() => {
                    handleChangeLocationFilter(filterString)
                    onClose()
                }}
                w="100%"
                textTransform={'uppercase'}
            >
                {filterString}
            </Button>

        )
    }

    return (
        <Box
            {...storyblokEditable(blok)}
            w="full"
        >
            {
                !blok.hideButtons &&
                <Flex width={"100%"} justifyContent={"center"} alignItems="center" mb={3}>
                    <HStack alignContent={"center"} >
                        <Box minWidth={{base: '75vw', md: '25rem'}} borderRadius={"lg"} borderWidth={"1px"} borderColor={"brand.green.600"} >
                            <Text textAlign={"center"} color={"brand.green.600"} fontSize={"xl"} fontWeight="bold" padding={1} mb={0}>{locationFilter}</Text>
                            </Box>
                        <Popover>
                            {({ isOpen, onClose }) => (
                                <>
                                    <PopoverTrigger>
                                        <Button variant={"ghost"}><FontAwesomeIcon icon={"sliders"}></FontAwesomeIcon></Button>
                                    </PopoverTrigger>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverHeader>Filters</PopoverHeader>
                                        <PopoverBody>
                                            <Stack>
                                                <FilterButton filterString="All Locations" onClose={onClose} />
                                                <FilterButton filterString="Featured" onClose={onClose} />
                                                {regionArray.length > 1 && regionArray.map((region, index) => <FilterButton key={index} filterString={region} onClose={onClose} />)}
                                            </Stack>
                                        </PopoverBody>
                                    </PopoverContent>
                                </>
                            )}
                        </Popover>

                    </HStack>
                </Flex>
            }

            <Flex flexWrap="wrap" gap="1.25rem" justifyContent="center" >
                {

                    filteredLocations.map((location, index) => {

                        return (


                            <CardV2
                                imageSrc={`${location?.image.filename}/m/650x650/smart`}
                                tagLine={!blok.hideCost &&
                                    <>From&nbsp;<Text as="span" color="brand.green.600">${location?.rootsData.pricedFrom.toLocaleString()}</Text></>
                                }
                                titleText={location?.region === 'USA' ? 'Domestic' : 'International'}
                                subText={location?.name}
                                key={location.rootsId}
                                locationLink={blok.disableLocationLink ? undefined : location}
                                locationLinkType="standard"
                                subTextWeight="bolder"
                                subTextFontSize="xl"
                                locationButtons={true}
                                box={{
                                    width: {base: "100%", md: "45%", lg: "31%"}}}

                            > </CardV2>



                        )
                    })
                }
            </Flex>
        </Box>
    )
};

const sortRegions = (regions: string[]) => {

    const sortMap = {
        "usa": "0",
        "caribbean": "1",
        "central america": "2",
        "canada": "3",
    }

    return regions.slice().sort((a, b) => {
        const lowerA = a.toLowerCase()
        const lowerB = b.toLowerCase()

        let aCompare = a
        let bCompare = b

        if (sortMap[lowerA]) aCompare = sortMap[lowerA]
        if (sortMap[lowerB]) bCompare = sortMap[lowerB]


        return aCompare.localeCompare(bCompare)

    })

}
    export default LocationCardsV2;
