import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import { storyblokEditable } from "@storyblok/react";

const Page = ({ blok, globalState, setGlobalState, locationState, ppm365, locale}: DynamicComponentProps<any>) => {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.body
        ? blok.body.map((blok) => (
            <DynamicComponent 
              blok={blok} 
              key={blok._uid} 
              globalState={globalState} 
              setGlobalState={setGlobalState} 
              locationState={locationState} 
              ppm365={ppm365}
              locale={locale}
            />
          ))
        : null}
    </main>
)
};

export default Page;
