import { Tabs, TabList, Tab, TabPanels, TabPanel, Text, Input, useRadio, Box, useRadioGroup, HStack, Button, Radio, RadioGroup, LinkBox, Link, Flex, VStack } from "@chakra-ui/react"
import { useSearchParams } from "next/navigation"
import React from "react"
import { SetStateAction, Dispatch, useState, useEffect, useMemo, useCallback } from "react"
import { FieldValues, FormState, useForm, UseFormGetValues, UseFormRegister, UseFormReturn } from "react-hook-form"
import { useWizard, Wizard } from "react-use-wizard"
import { getReadinessTrip, isUserOnReadinessTrip, registerForReadinessTrip } from "../../utils/ppmApi"
import { useReadinessTripFromUrl, useReadinessTripStatus } from "../../utils/readinessTripHooks"
import ButtonLink from "../buttons/ButtonLink"
import { DynamicComponentProps } from "../DynamicComponent"
import Line from "../layout/Line"
import SignInForm from "../layout/navigation/sign-in/SignInForm"
import SignUpForm from "../layout/navigation/sign-in/SignUpForm"


type RegistrationTypes = "single" | "multiple" | "traditional"

const RegisterReadinessTripForm = ({ blok, globalState, setGlobalState, locale }: DynamicComponentProps<RegisterReadinessTripFormBlok>): JSX.Element => {

    const userSignedIn = globalState.user?.email?.length > 0

    const { readinessTrip, location, disaster, isLoading } = useReadinessTripFromUrl()
    const { isOnReadinessTrip, tripId: existingTripId, isLoading: tripStatusLoading } = useReadinessTripStatus(readinessTrip, userSignedIn)

    const [regiType, setRegiType] = useState<RegistrationTypes>()
    const [registeredTripId, setRegisteredTripId] = useState<number | undefined>(undefined)


    const handleRegistrationComplete = (result: { tripId: number }) => {
        setRegisteredTripId(result.tripId)
    }

    //TODO: IF user signs out mid process, reset the form



    const form = useForm()


    return (

        <>

            {
                (isLoading || tripStatusLoading) ?
                    <Text>Loading</Text>

                    : disaster && location &&
                    <>

                        {isOnReadinessTrip
                            ?
                            <RegistrationComplete location={location} tripId={existingTripId}></RegistrationComplete>
                            :
                            <>
                                <Text as="h2">{disaster.title} </Text>
                                <Flex gap={3} alignItems="baseline">
                                    <Text as="h3">{location.name}</Text>
                                    <Text >{readinessTrip.startDate} to {readinessTrip.endDate}</Text>
                                </Flex>

                                <Line blok={{ color: "brand.green", height: 3, mobileHeight: 3, saturation: 600 }}></Line>

                                <Flex direction="column" paddingX={{ base: "0rem", md: "5rem" }} width="full">

                                    <Wizard>
                                        <RegistrationTypePicker form={form} selectionChange={(value) => setRegiType(value)}></RegistrationTypePicker>
                                        <SignInStep blok={blok} globalState={globalState} locale={locale} regiType={regiType} setGlobalState={setGlobalState} userSignedIn={userSignedIn}></SignInStep>
                                        <OtherAdultsForm></OtherAdultsForm>
                                        <SendRegistration
                                            formState={form.getValues()}
                                            trip={readinessTrip}
                                            location={location}
                                            disaster={disaster}
                                            globalState={globalState}
                                            onRegistrationComplete={handleRegistrationComplete}
                                        ></SendRegistration>
                                        <RegistrationComplete location={location} tripId={registeredTripId}></RegistrationComplete>
                                    </Wizard>
                                </Flex>
                            </>

                        }
                    </>


            }

        </>
    )
}



const RegistrationTypePicker = ({ form, selectionChange }: { form: UseFormReturn<FieldValues, any>, selectionChange?: (value: RegistrationTypes) => void }): JSX.Element => {


    const { nextStep, goToStep } = useWizard()

    const [regiType, setRegiType] = useState<RegistrationTypes>()

    const onTypeChange = (value) => {
        setRegiType(value)
        if (selectionChange) {
            selectionChange(value)
        }

    }

    useEffect(() => {
        setRegiType("single")
        if (selectionChange) {
            selectionChange("single")
        }

        nextStep()

    }, [selectionChange, nextStep])

    const options = [
        { label: 'Just Me', value: 'single' },
        { label: 'Me & Other Adults', value: 'multiple' },
        { label: "Me & My Youth Group (I'm the leader)", value: "traditional" }]




    // const group = getRootProps()

    return (

        <>
            <Text fontWeight="bold">Who is Coming?</Text>
            <RadioGroup name="who" defaultValue={form.getValues('regiType')} onChange={onTypeChange}>
                {/* <HStack {...group} > */}
                <VStack alignItems={"start"}>
                    <Radio alignItems="center"
                        {...form.register("regiType")}
                        value="single"
                    >
                        Just Me
                    </Radio>
                    <Radio
                        {...form.register("regiType")}
                        value="multiple"
                    >
                        Me & Other Adults
                    </Radio>
                    <Radio
                        {...form.register("regiType")}
                        value="traditional"
                    >
                        Me & My Youth Group (I&apos;m the leader)
                    </Radio>
                    {/* {options.map((option) => {
                    const radio = getRadioProps({ value: option.value })
                    return (
                        <RadioButton key={option.value} {...radio} {...form.register('regiType')} >
                            {option.label}
                        </RadioButton>
                    )
                })} */}
                </VStack>
            </RadioGroup>

            {
                regiType && <Button marginTop="2rem" colorScheme="brand.green" onClick={nextStep}>Next</Button>
            }

        </>

    )



}

const SignInStep = ({ blok, globalState, setGlobalState, locale, regiType, userSignedIn }: DynamicComponentProps<RegisterReadinessTripFormBlok> & { regiType: RegistrationTypes, userSignedIn: boolean }): JSX.Element => {

    const { nextStep, goToStep } = useWizard()



    const signInComplete = () => {
        if (regiType === "multiple") {
            nextStep()
        }
        else {
            goToStep(3)
        }

    }

    if (userSignedIn) {
        signInComplete()
    }

    return (
        <>
            <Text>If you have been on a PPM trip, you will utilize your Pelican Account credentials to log in. If you have not been on a PPM trip, please create an account.</Text>
            <Tabs colorScheme={"brand.green"} w={"full"}>
                <TabList>
                    <Tab>Create Account</Tab>
                    <Tab>Sign In</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SignUpForm
                            buttonText="Create an Account"
                            locale={locale}
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                            blok={blok}
                            captureBirthday={true}
                            minAge={18}
                            created={signInComplete}
                            capturePhone={true}
                            phoneInstructions={<Text>Providing your mobile phone number allows us to reach you quickly by text message prior to and during active disaster response.  By providing us with your mobile phone number, you consent to receiving text messages from Praying Pelican Missions</Text>}
                        ></SignUpForm>
                    </TabPanel>
                    <TabPanel>
                        <SignInForm
                            buttonText="Sign in"
                            locale={locale}
                            globalState={globalState}
                            setGlobalState={setGlobalState}
                            blok={blok}
                            signedIn={signInComplete}
                        ></SignInForm>
                    </TabPanel>
                </TabPanels>
            </Tabs>


        </>
    )
}

const OtherAdultsForm = (): JSX.Element => {

    const { nextStep, goToStep } = useWizard()

    const otherAdultsNext = () => {
        nextStep()
    }

    return (

        <>
            Entry forms for other adults
            <Button onClick={otherAdultsNext}>Next</Button>
        </>

    )

}

const CustomerSelectForm = (): JSX.Element => {
    const { nextStep, goToStep } = useWizard()

    return <></>

}

const SendRegistration = ({ formState, trip, location, disaster, globalState, onRegistrationComplete }: { formState: FieldValues, trip: ReadinessTrip, location: LbLocation, disaster: Disaster, globalState: GlobalState, onRegistrationComplete?: (result: { tripId: number, error?: Error }) => void }): JSX.Element => {

    console.log(`sending regi`, formState)

    const { nextStep } = useWizard()

    const handleRegisterClick = () => {
        const register = async () => {
            const result = await registerForReadinessTrip({ groupingId: trip.groupingId, dates: [] })

            if (!result.error && result.result && !result.result.error) {
                if (onRegistrationComplete) {
                    onRegistrationComplete(result.result)
                }
                nextStep()
            }
            else {
                alert("Error: " + (result.error ? JSON.stringify(result.error) : result.result.error.message))
            }

        }

        register()
    }
    return (
        <>
            <Text as="h3" marginBottom="2rem">Registration Details:</Text>
            <Flex direction="column">
                <Flex direction={{ base: "column", sm: "row" }} justifyContent="space-between">
                    <Flex direction="column">
                        <Flex>
                            <Text fontWeight="semibold" width="10rem">Response</Text>
                            <Text>{disaster.title}</Text>
                        </Flex>
                        <Flex>
                            <Text fontWeight="semibold" width="10rem">Location</Text>
                            <Text>{location.name}</Text>
                        </Flex>
                        <Flex >
                            <Text fontWeight="semibold" width="10rem">Service Window</Text>
                            <Flex direction={{ base: "column", sm: "row" }} gap={{base: 0, sm: 1}}>
                                <Text>{trip.startDate} to </Text>
                                <Text>{trip.endDate}</Text>
                            </Flex>

                        </Flex>
                        <Text>After you register, please go into your Pelican Account and select the days you are available.  After that is completed we will reach out to you to confirm which days we can receive you</Text>
                    </Flex>

                    <Flex direction="column">
                        <Flex>
                            <Text fontWeight="semibold" width="10rem">Registration Fee</Text>
                            <Text>$0.00</Text>
                        </Flex>
                        <Flex>
                            <Text fontWeight="semibold" width="10rem">Trip Cost</Text>
                            <Text>$0.00</Text>
                        </Flex>
                    </Flex>

                </Flex>


            </Flex>



            <Flex direction="column" width="full" marginTop={"2rem"}>
                <Text>By sending this registration request, you are confirming you are at least 18 years of age</Text>
                <Button colorScheme="brand.green" onClick={handleRegisterClick}>Send Registration</Button>
            </Flex>

        </>
    )
}

const RegistrationComplete = ({ location, tripId }: { location: LbLocation, tripId: number }): JSX.Element => {

    return (
        <>
            <Text fontSize="3xl" fontWeight="bold" color="brand.green.600">YOU ARE ALL SET!</Text>
            <Text>Thank you for committing to meeting the pressing needs of   <Text as="span" fontWeight={"bold"}>{location.name}</Text>
            </Text>

            <Text>Please sign in to your Pelican Account to complete all pre-service items before you arrive</Text>

            <LinkBox >
                <Link href={`https://account.ppm.org/?tripID=${tripId}`}>
                    <Button colorScheme="brand.green">SIGN IN TO PELICAN ACCOUNT</Button>
                </Link>
            </LinkBox>
        </>
    )
}

const RadioButton = React.forwardRef((props: any, ref): JSX.Element => {
    const { getInputProps, getRadioProps } = useRadio(props)
    const input = getInputProps()
    const checkbox = getRadioProps()

    return (
        <Box as='label'>
            <input ref={ref} {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'brand.green.600',
                    color: 'white',
                    borderColor: 'brand.green.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    )

})
RadioButton.displayName = "RadioButton"

const RegistrationInfoForm = (): JSX.Element => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm()
    const onSubmit = data => console.log(data)

    return (
        <>
            <Input
                type="date"
                variant="outline"
                layerStyle={'lightForm'}
            ></Input>
        </>
    )

}


export default RegisterReadinessTripForm