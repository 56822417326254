import { GridItem, Grid, Center, Text, SimpleGrid, Flex, Box } from '@chakra-ui/react'
import { storyblokEditable } from "@storyblok/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface LocationAtAGlanceProps {
  blok: any;
  globalState: GlobalState;
  locationState?: RootsLocation;
}

const LocationAtAGlance = ({ blok, globalState, locationState }: LocationAtAGlanceProps):JSX.Element => {
  const langArray = [];
  const ethArray = [];
  const relArray = [];

  if (locationState) {
    if (locationState.lang1 != '') langArray.push(locationState.lang1);
    if (locationState.lang2 != '') langArray.push(locationState.lang2);
    if (locationState.lang3 != '') langArray.push(locationState.lang3);
    
  
    if (locationState.eth1 != '') ethArray.push(locationState.eth1);
    if (locationState.eth2 != '') ethArray.push(locationState.eth2);
    if (locationState.eth3 != '') ethArray.push(locationState.eth3);
    
  
    if (locationState.rel1 != '') relArray.push(locationState.rel1);
    if (locationState.rel2 != '') relArray.push(locationState.rel2);
    if (locationState.rel3 != '') relArray.push(locationState.rel3);
  }

  let iconCount = 0;

  if (locationState?.population) iconCount += 1;
  if (locationState?.region) iconCount += 1;
  if (locationState?.climate) iconCount += 1;
  if (locationState?.income) iconCount += 1;
  if (langArray.length > 0) iconCount += 1;
  if (relArray.length > 0) iconCount += 1;
  if (ethArray.length > 0) iconCount += 1;

  return (
    <Grid 
      templateColumns={{sm: 'repeat(1, 1fr)', lg: 'repeat(12, 1fr)'}}
      {...storyblokEditable(blok)}
    >
      <GridItem colSpan={{base: 8, lg: 4}} bg='brand.darkgray.600'>
        <Flex
          direction="column"
          justifyContent="center"
          alignContent={'center'}
          h="full"
        >
          <Text 
            fontSize={{base: '2xl', lg: '3xl'}} 
            color='white' 
            textAlign={'right'} 
            textTransform={'uppercase'} 
            marginBottom="0" 
            padding="10"
            alignSelf={{base: 'center', lg: "flex-end"}}
          >
            {locationState?.name} <strong>At A Glance</strong>
          </Text>
        </Flex>
        
      </GridItem>

      <GridItem colSpan={8} bg='brand.green.600' color='white'>
        <SimpleGrid 
          columns={{base: 2, md: 3, lg: iconCount}} 
          textAlign='center' 
          h={{lg: '250px'}} 
          rowGap={4}
          gap={0}
          py={8}
        >
          {
            locationState?.population.toLocaleString() && 
            <AtAGlanceIcon icon="chart-bar" label="Population" data={locationState?.population.toLocaleString()} />
          }
          {
            locationState?.region && 
            <AtAGlanceIcon icon="globe-africa" label="Region" data={locationState?.region} />
          }
          {
            locationState?.climate &&
            <AtAGlanceIcon icon="cloud-sun" label="Climate" data={locationState?.climate} />
          }
          {
            `${locationState?.income.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3)}` &&
            <AtAGlanceIcon icon="money-bill" label="Income" data={`${locationState?.income.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3)} /year`} />
          }
          {
            langArray.join('<br />') && 
            <AtAGlanceIcon icon="language" label="Language" data={langArray.join('<br />')} />
          }
          {
            relArray.join('<br />') && 
            <AtAGlanceIcon icon="praying-hands" label="Religion" data={relArray.join('<br />')} />
          }
          {
            ethArray.join('<br />') && 
            <AtAGlanceIcon icon="users" label="Ethnicity" data={ethArray.join('<br />')} />
          }
        </SimpleGrid>
      </GridItem>

    </Grid>
  );
}

const AtAGlanceIcon = ({ label, icon, data }: { label: string, icon: any, data: string }):JSX.Element => (
  <Center h='100%'>
    <Flex direction="column" alignContent={'flex-start'} >
      <FontAwesomeIcon icon={['fas', icon]} size={'2x' as SizeProp} />
      <Text color="white" fontSize="sm">
        <strong>{label}</strong>
      </Text>
      <Text color="white" fontSize="sm" dangerouslySetInnerHTML={{ __html: data }} />
    </Flex>
  </Center>
)

export default LocationAtAGlance;
