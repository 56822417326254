import { Flex, Text } from "@chakra-ui/react";

const Placeholder = ({ componentName }) => (
  <Flex alignItems="center" justifyContent="center" height="100px" border="1px solid" borderColor={'brand.green.600'} borderRadius={'lg'}>
    <Text marginBottom={0}>
      The component <strong>{componentName}</strong> has not been created in the website template.
    </Text>
  </Flex>
);

export default Placeholder;
