import React from "react";
import { Flex, Text as ChakraText } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TwoToneText = ({ blok }) => {
  const justifyContent = blok.alignment === 'center' ? 'center' : blok.alignment === 'right' ? 'flex-end' : 'flex-start';
  const leftColor = (blok.leftColor === "white" || blok.leftColor === "black") ? blok.leftColor : `${blok.leftColor}.${blok.leftSaturation}`
  const rightColor = (blok.rightColor === "white" || blok.rightColor === "black") ? blok.rightColor : `${blok.rightColor}.${blok.rightColor}`
  return (
    <Flex 
      direction="row" 
      alignItems="center" 
      columnGap="2" 
      justifyContent={justifyContent}
      marginTop={blok.marginTop}
      marginBottom={blok.marginBottom}
      width="full"
      {...storyblokEditable(blok)} 
    >
      <ChakraText 
        fontWeight={blok.fontWeight} 
        fontSize={blok.size} 
        as={blok.style}
        textAlign={blok.alignment}
      >
        {
          blok.leftIcon && blok.leftIcon.icon !== '' &&
          <FontAwesomeIcon 
            icon={[blok.leftIcon.type, blok.leftIcon.icon]} 
            color={leftColor} 
            style={{marginRight: '10px'}}
          />
        }
        
        {
          blok.hideSpace ? 
            <ChakraText as="span" color={leftColor}>
              {blok.leftContent}<ChakraText as="span" color={rightColor}>{blok.rightContent}</ChakraText>
            </ChakraText>
          : 
            <ChakraText as="span" color={leftColor}>
              {blok.leftContent} <ChakraText as="span" color={rightColor}>{blok.rightContent}</ChakraText>
            </ChakraText>        
        } 

        
        
        {
          blok.rightIcon && blok.rightIcon.icon !== '' &&
          <FontAwesomeIcon
            icon={[blok.rightIcon.type, blok.rightIcon.icon]} 
            color={rightColor}
            style={{ marginLeft: '10px' }}
          />
        }
      </ChakraText>
    </Flex>
  );
};

export default TwoToneText;
