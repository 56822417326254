import { storyblokEditable } from "@storyblok/react";
import { Box, Button, Input, Radio, RadioGroup, Collapse, Flex, FormControl, 
         FormErrorMessage, FormLabel, GridItem, SimpleGrid, Text, useDisclosure, 
         Stack, Grid} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ChurchChampionTextField from "./ChurchChampionFormTextField";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import AlertModal from "../AlertModal";
import DateUtils from '../../../utils/dateUtils';
import React from "react";

export interface ChurchChampionFormProps {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  dob: Date;
  guardianFirst: string;
  guardianLast: string;
  guardianPhone: string;
  isRegistered: boolean;
  groupName: string;
  tripLocation: string;
  tripDate: Date;
  isChurchChampion: boolean;
}

const ChurchChampionForm = ({ blok, globalState, setGlobalState }) => {
  const [ isUnder18, setUnder18 ] = React.useState(false);
  const [ isRegistered, setRegistered ] = React.useState(false);
  const [ isChurchChampion, setChurchChampion ] = React.useState(false);
  blok.colorScheme = 'light';

  const onBirthDateChange = (e) => {
    const age = DateUtils.getAge(e.target.value);
    if (age < 18) {
      setUnder18(true);
    } else {
      setUnder18(false);
    }
  }

  const onRegisteredChange = (e) => {
    if (e === 'true') {
      setRegistered(true);
    } else {
      setRegistered(false);
    }
  }

  const onChurchChampionChange = (e) => {
    if (e === 'true') {
      setChurchChampion(true);
    } else {
      setChurchChampion(false);
    }
  }
  
  const [modalData, setModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: 'We have received your information, and someone will be following up with you soon. Thanks!'
  });
  
  const confirmModal = useDisclosure();

  const { register, reset, handleSubmit, getValues, formState, setValue } = useForm<ChurchChampionFormProps>({
    defaultValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      dob: null,
      guardianFirst: '',
      guardianLast: '',
      guardianPhone: '',
      isRegistered: false,
      groupName: '',
      tripLocation: '',
      tripDate: null,
      isChurchChampion: false
    }
  });

  useEffect(() => {
    setValue('email', globalState.user?.email ?? '');
  }, [globalState.user, setValue])

  const submitChurchChampionForm = async () => {
    const values = getValues();

    const currentPage = window ? window.location.pathname + window.location.search + window.location.hash : '';
    
    const submitData = {
      ...values
    }

    try {
        const sendChurchChampionForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/church-champion`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(submitData)
        })
  
        if (sendChurchChampionForm.ok) {
          setModalData({
            icon: ['far', 'circle-check'] as IconProp,
            iconColor: 'brand.green.600',
            title: 'Got It!',
            content: 'We have received your information, and someone will be following up with you soon. Thanks!'
          });
  
          confirmModal.onOpen();
  
          reset();
          setUnder18(false);
          setRegistered(false);  
        } else {
          console.error(sendChurchChampionForm)
          throw new Error('Failed to send ChurchChampion form')
        }
        
        setModalData({
          icon: ['far', 'circle-check'] as IconProp,
          iconColor: 'brand.green.600',
          title: 'Got It!',
          content: 'We have received your information, and someone will be following up with you soon. Thanks!'
        });
  
        confirmModal.onOpen();
  
        reset();
        setUnder18(false);
        setRegistered(false);  
      } catch (error) {
        console.error(error);
        setModalData({
          icon: ['fas', 'xmark'] as IconProp,
          iconColor: 'red.400',
          title: 'Oops!',
          content: 'Sorry, but there was an error submitting your information. Please try again later.'
        });
  
        confirmModal.onOpen();
      }
    }

  return (
    <>
      <AlertModal
        onClose={confirmModal.onClose}
        isOpen={confirmModal.isOpen}
        iconColor={modalData.iconColor}
        icon={modalData.icon}
        title={modalData.title}
        content={modalData.content}
      />

      <Grid templateColumns={{ base: '1fr', lg: `repeat(3, 1fr)` }}>
        <GridItem 
          colSpan={1}
          bgColor="brand.orange.600"
          display="flex"
          justifyContent={'center'}
          justifySelf={'center'}
          alignItems={'center'}
          alignSelf={'center'}
          flexDirection={{base: 'row', lg: 'column'}}
          textAlign={'center'}
          h={{base: 'full', lg: '84%'}}
          mt={{base: '2', lg: '0'}}
          w={{base: '85%', lg: 'full'}}
          borderRadius={'10px'}
        >
          <Text as="h2" color="white" mb={{base: '0', lg: "4"}} mr={{base: '3', lg: '0'}}>
            GET
          </Text>
          <Text as="h2" color="white" mb={{base: '0', lg: "4"}} mr={{base: '3', lg: '0'}}>
            MORE
          </Text>
          <Text as="h2" color="white">
            INFO
          </Text>
        </GridItem>

        <GridItem colSpan={2}
          bgColor=""
          p={{base: '5', lg: "10"}}
          pt={{base: '2', lg: '10'}}>
          <form onSubmit={handleSubmit(submitChurchChampionForm)} style={{width: '100%'}}>
            <Flex
              align="center"
              justify="center"
              className="ChurchChampion-form"
              w="full"
              p="5"
              {...storyblokEditable(blok)}
            >
              <Box w="full">
                <Flex
                  direction={{ base: 'column', lg: blok.horizontalLayout ? 'row' : 'column' }}
                  align={{ base: 'flex-start', lg: blok.horizontalLayout ? 'center' : 'flex-start' }}
                  gap={{ base: 0, lg: blok.horizontalLayout ? 5 : 0 }}
                  py={blok.horizontalLayout ? 5 : 0}
                  w="full"
                >
                  {blok.horizontalLayout &&
                    <Text
                      whiteSpace={'nowrap'}
                      mb={{ base: 5, lg: 0 }}
                      fontSize={{ base: '2xl', md: '3xl' }}
                      fontWeight="bold"
                      color="brand.darkgray.700"
                      textAlign={'center'}
                      width="full"
                    >
                      {blok.horizontalCallToAction}
                    </Text>
                  }

                  <FormLabel
                    color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                    fontWeight={'bolder'}
                  >
                    Contact Info
                  </FormLabel>

                  <Flex w={'full'} gap={{base: '0', lg: '5'}} align={'center'} direction={{ base: 'column', lg: blok.horizontalLayout ? 'column' : 'row' }}>
                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'firstname'}
                      fieldType={'text'}
                      fieldLabel={'First Name'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />

                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'lastname'}
                      fieldType={'text'}
                      fieldLabel={'Last Name'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />
                  </Flex>

                  <Flex w={'full'} gap={{base: '0', lg: '5'}} align={'center'} direction={{ base: 'column', lg: blok.horizontalLayout ? 'column' : 'row' }}>
                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'phone'}
                      fieldType={'tel'}
                      fieldLabel={'Phone Number'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />

                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'email'}
                      fieldType={'email'}
                      fieldLabel={'Email Address'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />
                  </Flex>

                  <FormControl isInvalid={!!formState.errors['dob']} mb={4}>
                    <FormLabel
                      color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                      fontWeight={'bolder'}
                    >
                      Date of Birth
                    </FormLabel>
                    <Input
                      name={'dob'}
                      type={'date'}
                      variant="outline"
                      size={blok.fieldSize}
                      layerStyle={blok.colorScheme === 'light' ? 'lightForm' : 'darkForm'}
                      placeholder={'Date of Birth (MM/DD/YYYY)'}
                      w={{base: 'full', lg: '48.2%'}}
                      {...register('dob', { required: 'Required' })}
                      onChange={onBirthDateChange}
                    />
                    {formState.errors?.dob &&
                      <FormErrorMessage>
                        {formState.errors.dob.message}
                      </FormErrorMessage>
                    }
                  </FormControl>

                  <Collapse in={isUnder18} style={{ width: '100%' }}>

                    <FormLabel
                      color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                      fontWeight={'bolder'}
                    >
                      Guardian Contact Info
                    </FormLabel>

                    <Flex w={'full'} gap={{base: '0', lg: '5'}} align={'center'} direction={{ base: 'column', lg: blok.horizontalLayout ? 'column' : 'row' }}>
                      <ChurchChampionTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'guardianFirst'}
                        fieldType={'text'}
                        fieldLabel={'Guardian First Name'}
                        isRequired={isUnder18}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />

                      <ChurchChampionTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'guardianLast'}
                        fieldType={'text'}
                        fieldLabel={'Guardian Last Name'}
                        isRequired={isUnder18}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />
                    </Flex>

                    <Flex w={{base: 'full', lg: '48.2%'}}>
                      <ChurchChampionTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'guardianPhone'}
                        fieldType={'phone'}
                        fieldLabel={'Guardian Phone Number'}
                        isRequired={isUnder18}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />
                    </Flex>
                    
                  </Collapse>

                  <FormLabel
                    color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                    fontWeight={'bolder'}
                  >
                    Mailing Address
                  </FormLabel>

                  <ChurchChampionTextField
                    showLabels={blok.showLabels}
                    colorScheme={blok.colorScheme}
                    fieldName={'address1'}
                    fieldType={'text'}
                    fieldLabel={'Street Address'}
                    isRequired={true}
                    horizontalLayout={blok.horizontalLayout}
                    fieldSize={blok.fieldSize}
                    register={register}
                    errors={formState.errors}
                    submitting={formState.isSubmitting}
                  />

                  <ChurchChampionTextField
                    showLabels={blok.showLabels}
                    colorScheme={blok.colorScheme}
                    fieldName={'address2'}
                    fieldType={'text'}
                    fieldLabel={'Apt, suite, etc (optional)'}
                    isRequired={false}
                    horizontalLayout={blok.horizontalLayout}
                    fieldSize={blok.fieldSize}
                    register={register}
                    errors={formState.errors}
                    submitting={formState.isSubmitting}
                  />

                  <Flex w={'full'} gap={{base: '0', lg: '5'}} align={'center'} direction={{ base: 'column', lg: blok.horizontalLayout ? 'column' : 'row' }}>
                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'city'}
                      fieldType={'text'}
                      fieldLabel={'City'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />

                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'state'}
                      fieldType={'text'}
                      fieldLabel={'State'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />

                    <ChurchChampionTextField
                      showLabels={blok.showLabels}
                      colorScheme={blok.colorScheme}
                      fieldName={'zipcode'}
                      fieldType={'text'}
                      fieldLabel={'ZIP/postal code'}
                      isRequired={true}
                      horizontalLayout={blok.horizontalLayout}
                      fieldSize={blok.fieldSize}
                      register={register}
                      errors={formState.errors}
                      submitting={formState.isSubmitting}
                    />
                  </Flex>

                  <ChurchChampionTextField
                    showLabels={blok.showLabels}
                    colorScheme={blok.colorScheme}
                    fieldName={'country'}
                    fieldType={'text'}
                    fieldLabel={'Country'}
                    isRequired={true}
                    horizontalLayout={blok.horizontalLayout}
                    fieldSize={blok.fieldSize}
                    register={register}
                    errors={formState.errors}
                    submitting={formState.isSubmitting}
                  />

                  <FormLabel
                    color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                    fontWeight={'bolder'}
                  >
                    Are you currently registered for a PPM trip?
                  </FormLabel>

                  <FormControl
                    borderRadius={'lg'}
                    isInvalid={!!formState.errors['occupationOther']}
                    isDisabled={formState.isSubmitting}
                    mb={5}
                  >
                    <RadioGroup defaultValue='false' 
                      {...register('isRegistered', { required: false })}
                      onChange={onRegisteredChange}>
                      <Stack direction='row'>
                        <Radio value='true'>Yes</Radio>
                        <Radio value='false'>No</Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>

                  <Collapse in={isRegistered} style={{ width: '100%' }}>

                    <FormLabel
                      color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                      fontWeight={'bolder'}
                    >
                      Trip Information
                    </FormLabel>

                    <Flex w={'full'} gap={{base: '0', lg: '5'}} align={'center'} direction={{ base: 'column', lg: blok.horizontalLayout ? 'column' : 'row' }}>
                      <ChurchChampionTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'groupName'}
                        fieldType={'text'}
                        fieldLabel={'Group Name'}
                        isRequired={isRegistered}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />

                      <ChurchChampionTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'tripLocation'}
                        fieldType={'text'}
                        fieldLabel={'Trip Location'}
                        isRequired={isRegistered}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />
                    </Flex>

                    <FormLabel
                      color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                      fontWeight={'bolder'}
                    >
                      Trip Date
                    </FormLabel>
                    <Flex w={{base: 'full', lg: '48.2%'}}>
                      <ChurchChampionTextField
                        showLabels={blok.showLabels}
                        colorScheme={blok.colorScheme}
                        fieldName={'tripDate'}
                        fieldType={'date'}
                        fieldLabel={'Trip Date'}
                        isRequired={isRegistered}
                        horizontalLayout={blok.horizontalLayout}
                        fieldSize={blok.fieldSize}
                        register={register}
                        errors={formState.errors}
                        submitting={formState.isSubmitting}
                      />
                    </Flex>
                  </Collapse>

                  <FormLabel
                    color={blok.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
                    fontWeight={'bolder'}
                  >
                    What information would you like to receive?
                  </FormLabel>

                  <FormControl
                    borderRadius={'lg'}
                    isInvalid={!!formState.errors['occupationOther']}
                    isDisabled={formState.isSubmitting}
                    mb={5}
                  >
                    <RadioGroup defaultValue='false' 
                      {...register('isChurchChampion', { required: false })}
                      onChange={onChurchChampionChange}>
                      <Stack direction='column'>
                        <Radio value='false'>
                          I would like to sign up to be a Church Advocate and receive materials to recruit sponsors.
                        </Radio>
                        <Radio value='true'>
                          I am a Church Champion, already fully sponsoring a church, and would like more information about the mission trip scholarship.
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </FormControl>

                  <Box w="full">
                    <SimpleGrid columns={{ base: 1, md: blok.showLabels ? 4 : 3 }}>
                      {
                        blok.showLabels &&
                        <GridItem colSpan={1}>&nbsp;</GridItem>
                      }

                      <GridItem colSpan={3}>
                        <Button
                          type="submit"
                          width="full"
                          colorScheme={blok.colorScheme === 'light' ? 'brand.orange' : 'brand.orange'}
                          variant={blok.colorScheme === 'light' ? 'solid' : 'solid'}
                          leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                          disabled={formState.isSubmitting}
                        >
                          {blok.submitText ?? 'Submit'}
                        </Button>
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </form>
        </GridItem>
      </Grid>
    </>
  );
}

export default ChurchChampionForm;