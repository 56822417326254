import { Table, Thead, Tr, Th, Tbody, Td, Flex, Button, Text, useDisclosure, Tooltip } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import Link from "next/link";

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useIsMounted } from "usehooks-ts";
import DateUtils from "../../utils/dateUtils";
import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import { TableCardContent } from "../layout/tables/Tables";
import RequestInfoModal from "../misc/contact-form/RequestInfoModal";

interface ReadinessTripListProps extends DynamicComponentProps<ReadinessTripListBlok> {
}

const ReadinessTripList = ({ blok, globalState, setGlobalState, locale }: ReadinessTripListProps): JSX.Element => {


   const locations = globalState?.componentSpecificData?.readinessTrips?.locations
   const responses = globalState?.componentSpecificData?.readinessTrips?.responses
   const infoRequestModal = useDisclosure();
   const [infoRequestTrip, setInfoRequestTrip] = useState<CustomTripSearchResult | null>(null);

   const trips =  useMemo(() => {
    const tempTrips = globalState?.componentSpecificData?.readinessTrips?.trips
    if (tempTrips) {
      return tempTrips
      .filter((t) => blok.showPastTrips || (DateTime.fromFormat(t.endDate, "yyyy-MM-dd").valueOf() > DateTime.now().valueOf()))
      .sort((a,b) => DateTime.fromFormat(a.startDate, 'yyyy-MM-dd').valueOf() - DateTime.fromFormat(b.startDate, 'yyyy-MM-dd').valueOf())
    }
    else {
      return []
    }
   }, [blok.showPastTrips, globalState?.componentSpecificData?.readinessTrips?.trips]) 

   
   const openRequestInfoModal = (trip: ReadinessTrip) => {
    const location = locations.find(l => l.id === trip.locId)
    setInfoRequestTrip({
        airport: "",
        cost: 0,
        duration: 0,
        enddate: trip.endDate,
        hash: "",
        limitedAvailability: 0,
        location: location?.name,
        locID: trip.locId,
        theLocation: location?.name,
        tripdate: trip.startDate
    });
    infoRequestModal.onOpen();
  }

    return (
      
        (trips && trips.length === 0 )
        ? (
          blok.emptyContent && blok.emptyContent.length > 0 
          ? <>{blok.emptyContent.map(blok => <DynamicComponent key={blok._uid} blok={blok} globalState={globalState} setGlobalState={setGlobalState} locale={locale}></DynamicComponent>)}</>
          : <Text align="center">There are currently no Readiness Team deployment trips at this time.</Text>
        )

      :
        <>
     <RequestInfoModal
        onClose={infoRequestModal.onClose}
        isOpen={infoRequestModal.isOpen}
        trip={infoRequestTrip}
        globalState={globalState}
        setGlobalState={setGlobalState}
        dontBlockScrollOnMount={true}
      />
        <Table
            w="full"
            className="cards"
          >
            <Thead>
              <Tr>
                <Th>
                  <Text as="h6">Response</Text>
                </Th>
                <Th>
                  <Text as="h6">Location</Text>
                </Th>
                <Th textAlign="center">
                  <Flex gap={1} justifyContent="center" alignItems={"center"}>
                  <Text as="h6">Service Window</Text>
                  <Tooltip label="Come any time within this window. Serve the whole time, or just a day.">
                    <FontAwesomeIcon icon="circle-info"></FontAwesomeIcon>
                  </Tooltip>
                  </Flex>
                  
                </Th>
      
                <Th textAlign="center">
                  <Flex gap={1} justifyContent="center" alignItems={"center"}>
                  <Text as="h6">Airport</Text>
                    <Tooltip label="You are certainly able to drive. But if you need to fly, this is the closest large airport.">
                      <FontAwesomeIcon icon="circle-info"></FontAwesomeIcon>
                    </Tooltip>
                  </Flex>
                  
                </Th>
               
                <Th textAlign="center">
                  <Text as="h6">Actions</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {trips?.map((trip) => {
                const location = locations.find(l => l.id === trip.locId)
                const response = responses.find(r => r.id === trip.disasterId)
                return (
                  <Tr key={`${trip.groupingId}`}>
                    <Td fontWeight="bold" whiteSpace={{ base: 'normal', xl: 'nowrap' }} >
                      <TableCardContent label="Response" body={response.title} />
                    </Td>
                    <Td  whiteSpace={{ base: 'normal', xl: 'nowrap' }}>
                      <TableCardContent label="Location" body={location.name} />
                    </Td>

                    <Td textAlign="center" whiteSpace="nowrap">
                      <TableCardContent label="Trip Date" body={`${DateUtils.convertFormat(trip.startDate, 'yyyy-MM-dd', 'MM/dd/yyyy')} - ${DateUtils.convertFormat(trip.endDate, 'yyyy-MM-dd', 'MM/dd/yyyy')}`} />
                    </Td>

                   
                    <Td textAlign="center">
                      <TableCardContent label="Airport" body={`${location.airport1 ?? ''}`} />
                    </Td>

                   

                    <Td>
                    { true ? //trip.limitedAvailability === 0 || trip.limitedAvailability === undefined ?
                      <Flex direction={{ base: 'column', lg: 'row' }} gap="3" justifyContent={'center'} alignItems={'center'}>
                        <Link href={`/readiness-registration?groupingId=${trip.groupingId}` } target="_blank"  >
                          <Button
                            sx={{ '&:hover': { textDecoration: 'none' } }}
                            size="sm"
                            colorScheme="brand.green"
                            variant="outline"
                            flexGrow={{ base: 0, lg: 1 }}
                            w={{ base: 'full', lg: 'auto' }}
                            leftIcon={<FontAwesomeIcon icon={['fas', 'edit']} />}
                          >
                            Register 
                          </Button>
                        </Link>

                        <Button
                          size="sm"
                          colorScheme="brand.orange"
                          flexGrow={{ base: 0, lg: 1 }}
                          w={{ base: 'full', lg: 'auto' }}
                          leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                          onClick={() => {
                            openRequestInfoModal(trip);
                          }}
                        >
                          Request Info
                        </Button>
                      </Flex>
                      :  ( //Limited Availability
                        <Flex direction="row" gap="3" justifyContent={'center'} alignItems={'center'}>
                          <Button
                            size="sm"
                            colorScheme="brand.orange"
                            flexGrow={{ base: 0, xl: 1 }}
                            w={{ base: 'full', xl: 'auto' }}
                            leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                            onClick={() => {
                              openRequestInfoModal(trip);
                            }}
                          >
                            Limited Availability - Request Info
                          </Button>
                        </Flex>)
                        }
                    </Td>
                  </Tr>
                )
              }
              )}
            </Tbody>
          </Table>
          
        </>
            
    )
}

export default ReadinessTripList

