import { Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Image, Box, Button, Center, Link, ModalFooter } from "@chakra-ui/react"
import { DateTime } from "luxon"
import DateUtils from "../../../utils/dateUtils"



import Fancybox from "../../misc/Fancybox"

export interface PartnerUpdateModalProps {
    update: PartnerUpdate,
    isOpen: boolean,
    onClose: () => void
}

export const PartnerUpdateModal = ({ update, isOpen, onClose }: PartnerUpdateModalProps): JSX.Element => {


    return (update &&
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Box id={`entry-${update.id}`}>
                        {
                            <Text as="h3">{update.entrytitle}</Text>
                        }

                        <Text><>by {update.author} | {DateUtils.parseServerDate(update.entrydate, "local").toFormat("M/d/yyyy")}</></Text>

                        <div dangerouslySetInnerHTML={{ __html: update.entry }} style={{ marginBottom: '15px' }}></div>

                        {update.photo === 1 &&
                            <Fancybox options={{ infinite: false }}>
                                <Image
                                    cursor="pointer"
                                    data-fancybox="gallery"
                                    data-src={update.photoUrl}
                                    data-caption={`
              <b style="margin-bottom: 0px;">${update.entrytitle}</b><br />
              <em style="font-size: 12px;">by ${update.author}, ${update.entrydate}</em><br />
              ${update.entry.replaceAll('<p', '<p style="color:white;"')}`
                                    }
                                    src={update.photoUrl}
                                    alt={update.entrytitle}
                                    w="full"
                                    h="auto"
                                    borderRadius={'xl'}
                                />
                            </Fancybox>
                        }
                        {
                            update.video === 1 &&
                            <>
                                <div className='embed-container'>
                                    <iframe src={`https://www.youtube.com/embed/${update.youtubeId}`} frameBorder='0' allowFullScreen></iframe>
                                </div>
                            </>
                        }
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme={"brand.green"} onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default PartnerUpdateModal