import { Box, Flex, GridItem, LinkBox, LinkOverlay, SimpleGrid, Text, Grid, Button } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import Image from "next/legacy/image";
import Link from "next/link";
import DateUtils from '../../utils/dateUtils'
import { formatDate } from "../../utils/dateUtils";


interface Props {
  blok: any;
  globalState: GlobalState;
}

const DisasterResponseList = ({ blok, globalState }: Props): JSX.Element => {

  const [visibleCount, setVisibleCount] = useState(10);
  const [errorImages, setErrorImages] = useState<{ [id: number]: boolean }>({});

  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 10);
  }

  let disasters = globalState.disasters;

  if (blok.showPastDisasters) {
    if (globalState?.componentSpecificData?.pastDisasters) {
      disasters = globalState.componentSpecificData.pastDisasters
    }

  const handleImageError = (id: number) => {
    setErrorImages((prevErrors) => ({ ...prevErrors, [id]: true }));
  };

  if (!disasters) return null;

  return (
    <>
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap="4"
        m="4"
        //autoRows="minmax(150px, auto)"
        {...storyblokEditable(blok)}
      >
        {disasters.slice(0, visibleCount).map((disaster) => {

          return (
            <Box key={disaster.disasterID}>
              <LinkBox
                key={disaster.disasterID}
                cursor={'pointer'}
                color="brand.green.600"
                _hover={{
                  textDecoration: 'underline',
                }}
                width="500px"
                overflow="hidden"
                borderWidth="1px"
                borderRadius={'lg'}
                borderColor="brand.green.600"
                padding="3"
              >
                <Link href={`/response/${disaster.url}`} passHref legacyBehavior>
                  <LinkOverlay>
                    <SimpleGrid
                      columns={4}
                      spacing={4}
                      alignItems="center"
                    >
                      <GridItem>
                        <Box
                          rounded="xl"
                          overflow="hidden"
                          width="80px"
                          height="60px"
                          position="relative"
                        >
                          {!errorImages[disaster.disasterID] ? (
                            <Image
                              src={`https://images.ppm.org/upload/disasterphotos/thumb/${disaster.url}.jpg?v=${disaster.photoVersion}`}
                              alt={disaster.title || "Disaster image"}
                              layout="fill"
                              objectFit="cover"
                              onError={() => handleImageError(disaster.disasterID)}
                            />
                          ) : (
                           ''
                          )}
                        </Box>
                      </GridItem>
                      <GridItem colSpan={3}>
                        <Text fontWeight={'bold'} color="brand.green.600" mb="0">
                          {disaster.title}
                        </Text>
                        <Text>{formatDate(disaster.dateCreated)}</Text>
                      </GridItem>
                    </SimpleGrid>
                  </LinkOverlay>
                </Link>
              </LinkBox>
            </Box>
          );
        })}
      </Grid>
      <Box>
        {visibleCount < disasters.length && (
          <Button onClick={loadMore} mb="4" variant="unstyled" color="brand.green.600">See more...</Button>
        )}
      </Box>
    </>
  );
};
}

export default DisasterResponseList;
