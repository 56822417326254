import { Box, Text, useDisclosure } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import SubscribeForm from "./SubscribeForm";

interface Props {
  blok: any;
  globalState: GlobalState;
}

const TripJournalSubscribe = ({ blok, globalState }: Props):JSX.Element => {
  const journalData = globalState.journalData;

  const [modalData, setModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: `You're now subscribed to receive updates when new posts are made to this trip.`
  });

  const confirmModal = useDisclosure();

  if (!journalData) return null;
  if (globalState.journalData.TRIPINFO.beforeTripDate === 1 && blok._uid !== 'countdown-subscribe') return null;

  const submitSubscribeForm = async (values: {email: string}) => {
    const submitData = {value: values.email, type: 'email'};

    try {
      const sendForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/journals/${globalState.journalTripId}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
      })

      if (sendForm.ok) {
        setModalData({
          icon: ['far', 'circle-check'] as IconProp,
          iconColor: 'brand.green.600',
          title: 'Got It!',
          content: `You're now subscribed to receive updates when new posts are made to this page.`
        });

        confirmModal.onOpen();

      } else {
        console.error(sendForm);
        throw new Error('Failed to send subscription form')
      }
      
      confirmModal.onOpen();

    } catch (error) {
      console.error(error);
      setModalData({
        icon: ['fas', 'xmark'] as IconProp,
        iconColor: 'red.400',
        title: 'Oops!',
        content: 'Sorry, but there was an error submitting your information. Please try again later.'
      });

      confirmModal.onOpen();
    }
  }

  return (
    <Box 
      w="full" 
      p="5"
      bgColor="brand.green.50"
      borderRadius={'xl'}
      textAlign="center"
      borderColor={'brand.green.400'}
      borderWidth="4px"
      {...storyblokEditable(blok)}
    >
      <Text as="h4" color="brand.darkgray.600" fontWeight={'bold'} textTransform={'uppercase'} mb="3">Subscribe To Updates</Text>
      <SubscribeForm submitHandler={submitSubscribeForm} alertModal={{modalData, confirmModal}} />
    </Box>
  )
}

export default TripJournalSubscribe;