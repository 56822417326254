import {
  Stack,
  Flex,
  Text,
  Container,
  Box,
  SimpleGrid,
  GridItem,
  useInterval,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { getImageSrcFromRootsId } from '../../utils/misc';
import HeroFullBackgroundAutoHeight from '../layout/hero/HeroFullBackgroundAutoHeight';
import TripJournalSubscribe from './TripJournalSubscribe';

interface Props {
  blok: any;
  globalState: GlobalState;
}

export default function TripJournalHero({blok, globalState}:Props):JSX.Element {
  const entryId = globalState.entryId;  
  const journalId = globalState.journalTripId;  
  const journalData = globalState.journalData;  

  const [tripdateCountdown, setTripDateCountdown] = useState<{days: number, hours: number, minutes: number}>({days: 0, hours: 0, minutes: 0});
  
  const updateCountdown = () => {
    const midnight = new Date((new Date).setHours(24, 0, 0, 0));
    const midnightLuxon = DateTime.fromJSDate(midnight);
    const tripStartDate = DateTime.fromFormat(journalData.TRIPINFO.tripdateCountdown, 'yyyy/MM/dd');

    const daysTillTrip = Math.floor(tripStartDate.diffNow('days').toObject().days);
    const hoursTillTrip = Math.floor(midnightLuxon.diffNow('hours').toObject().hours);
    const minutesTillTrip = Math.floor((midnightLuxon.diffNow('minutes').toObject().minutes % 60));

    setTripDateCountdown({ days: daysTillTrip, hours: hoursTillTrip, minutes: minutesTillTrip });
  }

  // init countdown
  useEffect(updateCountdown, [journalData.TRIPINFO.tripdateCountdown]);
  
  // update the countdown once per minute to avoid unnecessary re-renders
  useInterval(updateCountdown, journalData.TRIPINFO.beforeTripDate === 1 ? 60000 : null)
  
  if (entryId) return null;
  if (!journalId) return null;

  const groupPhotoUrl = journalData.TEAMPHOTOSET ? `https://images.ppm.org/upload/groupphotos/${journalId}.jpg` : getImageSrcFromRootsId(journalData.TRIPINFO.locID, globalState.locations);

  const staffList = journalData.ATLINFO.flatMap(staff => {
    const atls = [journalData.TRIPINFO.tlName];
    
    for (let i = 1; i <= 16; i += 1) {
      if (staff[`atl${i}Name`]) {
        atls.push(staff[`atl${i}Name`]);
      }
    }

    return atls;
  });

  return (
    <Box
      cursor={journalData.TRIPINFO.beforeTripDate === 0 ? "pointer" : "inherit"}
      data-fancybox={journalData.TRIPINFO.beforeTripDate === 0 ? "gallery" : null}
      data-src={journalData.TRIPINFO.beforeTripDate === 0 ? groupPhotoUrl : null}
      data-caption={journalData.TRIPINFO.beforeTripDate === 0 ? `
        <b>${journalData?.TRIPINFO?.groupname}</b><br />
        Serving In ${journalData?.TRIPINFO?.theLocationName}<br />
        ${journalData?.TRIPINFO?.tripdateFormatted}
      ` : null}
    >
      <HeroFullBackgroundAutoHeight photoSrc={groupPhotoUrl} shading={400}>
        <Flex
          w={'full'}
          px={{ base: 4, lg: 8 }}
          mt={{base: 0, lg: 20}}
        >
          <Container maxWidth="container.xl" mt={10}>
            <SimpleGrid columns={{base: 1, lg: 2}}>
              <GridItem>
                <Stack justifyContent={'center'}>
                  <Text
                    as="h1"
                    textShadow={`2px 2px 1px black`}
                    color={'white'}
                  >
                    {journalData?.TRIPINFO?.groupname}
                  </Text>
                  <Text 
                    as="h2"
                    color={'white'}
                    fontSize="xl"
                    lineHeight="1.3"
                    textShadow={`1px 1px 1px black`}
                  >
                    Serving In {journalData?.TRIPINFO?.theLocationName}<br />
                    {journalData?.TRIPINFO?.tripdateFormatted}<br />
                    
                    {
                      journalData?.COMMUNITYINFO?.communityList && 
                      journalData?.COMMUNITYINFO?.communityList !== '' && 
                      <>
                      Community: <span style={{ fontWeight: 'normal' }}>{journalData?.COMMUNITYINFO?.communityList}</span><br />
                      </>
                    }

                    {
                      journalData?.PARTNERINFO?.partnerList && 
                      journalData?.PARTNERINFO?.partnerList !== '' && 
                      <>
                        Partner: <span style={{fontWeight: 'normal'}}>{journalData?.PARTNERINFO?.partnerList}</span><br />
                      </>
                    }
                    
                    {
                      staffList.length > 0 && 
                      <>
                        Staff: <span style={{ fontWeight: 'normal' }}>{staffList.join(', ')}</span>
                      </>
                    }
                  </Text>
                </Stack>
              </GridItem>
              {
                journalData.TRIPINFO.beforeTripDate === 1 && 
                <GridItem mt={{base: 5, lg: 0}}>
                  <Flex 
                    direction="column"
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap="5"
                  >
                    <Text
                      as="h2"
                      color={'white'}
                      fontSize="2xl"
                      textShadow={`2px 2px 2px black`}
                    >
                      This trip starts in...
                    </Text>

                    <Flex direction={{base: 'column', lg: "row"}} gap="2" flexWrap={'wrap'}>
                      <CountdownBox time={tripdateCountdown.days} unit={"days"} />
                      <CountdownBox time={tripdateCountdown.hours} unit={"hours"} />
                      <CountdownBox time={tripdateCountdown.minutes} unit={"minutes"} />
                    </Flex>

                    <Box maxW="325px">
                      <TripJournalSubscribe blok={{'_uid': 'countdown-subscribe'}} globalState={globalState} />
                    </Box>
                  </Flex>
                </GridItem>
              }
            </SimpleGrid>
          </Container>
        </Flex>
      </HeroFullBackgroundAutoHeight>
    </Box>
  );
}

const CountdownBox = ({time, unit}) => {
  return (
    <Flex
      bgColor="blackAlpha.600"
      borderRadius="lg"
      borderColor="brand.green.600"
      borderWidth={1}
      w="140px"
      h="120px"
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontWeight={'bold'} textColor="white" as="h3" textAlign={'center'} mb="0">{time}</Text>
      <Text textColor="white" textAlign={'center'} textTransform="uppercase" mb="0">{unit}</Text>
    </Flex>
  )
}