import React, { useMemo } from "react";
import { storyblokEditable } from "@storyblok/react";
import { Box } from "@chakra-ui/react";
import { getBounds, MarkerMap } from "../../utils/maps";

const LocationMap = ({ blok, globalState }):JSX.Element => {
  const locations = globalState.locations
    .filter(location => {
      if (blok.regionFilter === 'All') return true;

      if (blok.regionFilter === location.region) return true;

      if (blok.regionFilter === 'International' && location.rootsData.domestic === 0) return true;

      if (blok.regionFilter === 'Domestic' && location.rootsData.domestic === 1) return true;

      return false;
    })
    .map(location => ({name: location.name, lat: location.rootsData.lat, lng: location.rootsData.lng}));
  
  const bounds = useMemo(() => getBounds(locations, 300, 300), [locations]);
  
  if (globalState.locations.length === 0) return null;

  const defaultProps = {
    center: {
      lat: bounds.center.lat,
      lng: bounds.center.lng
    },
    zoom: bounds.zoom,
    scrollWheel: false,
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
  };

  return (
    <Box
      {...storyblokEditable(blok)}
      height="400px"
      w="full"
      borderRadius="xl"
      overflow="hidden"
    >
      <MarkerMap defaultProps={defaultProps} markers={locations} />
    </Box>
  );
}

export default LocationMap;
