import React, { useContext } from 'react';
import {
  Box,
  Divider,
  Text,
  VStack,
  Center
} from '@chakra-ui/react';
import JournalEntry from './JournalEntry';
import Link from 'next/link';
import { storyblokEditable } from '@storyblok/react';

interface Props {
  blok: any;
  globalState: GlobalState;
}

const TripJournalEntryList = ({ blok, globalState }: Props): JSX.Element => {
  const journalId = globalState.journalTripId;
  const journalData = globalState.journalData;

  if (globalState.journalData.TRIPINFO.beforeTripDate === 1) return null;

  return (
    <Box {...storyblokEditable(blok)} width={{ base: '90%', lg: '100%' }} >
      <VStack
        gap="6"
        divider={
          <Divider
            borderColor={'brand.darkgray.200'}
            sx={{ marginBottom: '-10px!important' }}
          />
        }
      >
        {
          globalState.journalData?.ENTRYLIST.length == 0 &&
          <Text as="p" fontSize="xl" fontWeight={'bold'}>No updates have been posted at this time. Check back soon!</Text>
        }

        {
          globalState.journalData?.ENTRYLIST
            .filter((entry) => {
              if (globalState.entryId) {
                return Number(entry.journalID) === Number(globalState.entryId);
              } else {
                return true;
              }
            })
            .map((entry, index) => (
              <JournalEntry
                {...entry}
                key={index}
                photoUrl={
                  entry.photo === 0
                    ? ''
                    : `https://trip-journals.storage.googleapis.com/${entry.tripID}/${entry.journalID}.jpg`
                }
              />
            ))
        }
      </VStack>
    </Box>
  );
}

export default TripJournalEntryList;