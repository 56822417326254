import { Box, BoxProps, Button, ChakraComponent, Flex, GridItem, GridItemProps, InteractivityProps, LinkBox, LinkOverlay, ResponsiveValue, Tag, Text, TextProps } from "@chakra-ui/react"
import Image from "next/legacy/image"
import { Key } from "react"
import { ConditionalWrapper } from "../misc/ConditionalWrapper"
import { LocationLink, LocationLinkType } from "../locations/LocationLink"
import Link from "next/link"
import { DisasterResposneLink } from "../disaster/DisasterResponseLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export interface CardV2Props {
    imageSrc: string,
    imageAlt?: string,
    imageBlurDataUrl?: string,
    children?: React.ReactNode,
    tagLine: React.ReactNode,
    titleText: string,
    subText: string,
    key: Key,
    box: BoxProps,
    linkHref?: string,
    locationLink?: StoryblokLocation,
    locationLinkType?: LocationLinkType
    height?: BoxProps["height"];
    titleWeight?: TextProps["fontWeight"]
    titleFontSize?: TextProps["fontSize"]
    subTextWeight?: TextProps["fontWeight"]
    subTextFontSize?: TextProps["fontSize"]
    locationButtons?: boolean


}



export const CardV2 = ({
    imageSrc,
    imageAlt,
    imageBlurDataUrl,
    tagLine,
    titleText,
    subText,
    box,
    locationLink,
    locationLinkType,
    height,
    titleWeight,
    titleFontSize,
    subTextWeight = "bolder",
    subTextFontSize = "xl" }: CardV2Props) => {

    let placeholder: "blur" | "empty" = "empty";

    if (imageBlurDataUrl) placeholder = "blur";

    if (!box.height) box.height = "250px"
    if (!box.rounded) box.rounded = "xl"
    if (!box.boxShadow) box.boxShadow = "dark-lg"


    return (
        <Box
            position="relative"
            overflow={'hidden'}
            {...box}
        >
            <>
                <Image
                    unoptimized={imageSrc.includes("a.storyblok.com")}
                    src={imageSrc}
                    alt={imageAlt}
                    objectFit='cover'
                    objectPosition={'center center'}
                    layout="fill"
                    blurDataURL={imageBlurDataUrl}
                    placeholder={placeholder}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    padding="3"
                    width="100%"
                    height="100%"
                    bgColor="blackAlpha.400"
                    rounded={'xl'}
                    overflow="hidden"
                >
                    <Flex
                        direction="column"
                        justifyContent="space-between"
                        rounded={'xl'}
                        overflow="hidden"
                        width="100%"
                        height="100%"
                        padding="3"
                        transition="background-color 0.1s"
                    >

                       

                            <Box>
                                <Flex gap={3} width="full" justifyContent="space-between">
                                    <Text
                                        color="white"
                                        textTransform="uppercase"
                                        letterSpacing={'tighter'}
                                        fontWeight={titleWeight}
                                        fontSize={titleFontSize}
                                        marginBottom="1"
                                    >
                                        {titleText}
                                    </Text>
                                    {
                                        tagLine && <Tag variant="solid" alignSelf="center" bgColor="blackAlpha.800" textTransform={'uppercase'} fontWeight="bold" fontSize="xs">
                                            {tagLine}
                                        </Tag>
                                    }

                                </Flex>


                                <Text
                                    color="white"
                                    textTransform="uppercase"
                                    fontSize={subTextFontSize}
                                    fontWeight={subTextWeight}
                                    marginBottom="0"
                                >
                                    {subText}
                                </Text>

                            </Box>

                      


                            <Flex flexDirection={"row"} gap={3}>

                                <LocationLink location={locationLink} linkType={locationLinkType}>
                                    <Button
                                        size="xs" colorScheme="brand.green" backgroundColor="white" variant="outline"
                                    >
                                        <Box marginRight="1"><FontAwesomeIcon icon="info-circle"></FontAwesomeIcon></Box> Learn More
                                    </Button>
                                </LocationLink>

                                <LocationLink location={locationLink} linkType="trip-search">
                                    <Button size="xs" colorScheme="brand.green"
                                    >
                                        <Box marginRight="1"><FontAwesomeIcon icon="search"></FontAwesomeIcon></Box> Search Dates
                                    </Button>
                                </LocationLink>

                            </Flex>
                       
                    </Flex>
                </Box >
            </>
        </Box >
    );

}


