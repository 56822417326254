import { Box, Button, Flex, GridItem, LinkBox, LinkOverlay, SimpleGrid, Tag, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import Link from "next/link";
import React, { useState } from "react";
import { filterLocations } from "../../utils/locationHooks";
import { Card } from "../layout/Card";
import { Cards } from "../layout/Cards";
import { LocationLink } from "./LocationLink"

const LocationCards = ({ blok, globalState }) => {
  const [locationFilter, setLocationFilter] = useState<string>('All Locations');



  const handleChangeLocationFilter = (filterValue: string) => {
    setLocationFilter(filterValue);
  }

  const locations = globalState.locations.filter(location => {
    if (blok.regionFilter === 'All' || !blok.regionFilter) return true;
    if (blok.regionFilter === location.region) return true;
    if (blok.regionFilter === 'International' && location.rootsData.domestic === 0) return true;
    if (blok.regionFilter === 'Domestic' && location.rootsData.domestic === 1) return true;

    return false;
  });

  const filteredLocations = filterLocations(locationFilter, locations)

  const regions: string[] = locations.map(location => location.region).sort((a, b) => a.localeCompare(b));
  const regionArray = Array.from(new Set(regions));

  let buttonColumns = Math.min(regionArray.length + 2, 3);

  const GridButton = ({ filterString }) => {
    return (
      <GridItem>
        <Button
          size="sm"
          variant={filterString === locationFilter ? 'solid' : 'outline'}
          colorScheme="brand.green"
          onClick={() => handleChangeLocationFilter(filterString)}
          w="100%"
          textTransform={'uppercase'}
        >
          {filterString}
        </Button>
      </GridItem>
    )
  }

  return (
    <Box
      {...storyblokEditable(blok)}
      w="full"
    >
      {
        !blok.hideButtons &&
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: buttonColumns }}
          alignItems="center"
          justifyContent="center"
          w="full"
          gap="4"
          mb="5"
        >
          <GridButton filterString="All Locations" />
          <GridButton filterString="Featured" />
          {regionArray.length > 1 && regionArray.map((region, index) => <GridButton key={index} filterString={region} />)}
        </SimpleGrid>
      }

      <Cards>
        {

          filteredLocations.map((location, index) => {

            return (


              <Card
                imageSrc={`${location?.image.filename}/m/650x650/smart`}
                tagLine={!blok.hideCost &&
                    <>From&nbsp;<Text as="span" color="brand.green.600">${location?.rootsData.pricedFrom.toLocaleString()}</Text></>
                 }
                titleText={location?.region === 'USA' ? 'Domestic' : 'International'}
                subText={location?.name}
                key={index}
                gridItem={{ cursor: blok.disableLocationLink ? "default" : "pointer" }}
                locationLink={blok.disableLocationLink ? undefined : location}
                locationLinkType="standard"
              > </Card>



            )
          })
        }
      </Cards>
    </Box>
  )
};

const LocationCardContent = ({ blok, location }) => {
  return (


    <>


    </>
  )
}

export default LocationCards;
