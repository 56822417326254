import { storyblokEditable } from "@storyblok/react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from "@chakra-ui/react";

interface LocationInfoProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
}

const LocationInfo = ({ blok, globalState, locationState }: LocationInfoProps):JSX.Element => {
  return (
    <Flex
      {...storyblokEditable(blok)}
      direction={'column'}
      w="full"
      p="10"
    >
      <Text as="h2" color="white"mb="5">
        {locationState?.name} Location Info
      </Text>

      {locationState?.basics && <LocationInfoAccordion title={`${locationState?.name} Basics`} content={locationState?.basics} />}
      {locationState?.cls && <LocationInfoAccordion title={`Climate, Language, & Safety`} content={locationState?.cls} />}
      {locationState?.econ && <LocationInfoAccordion title={`Economy & Currency`} content={locationState?.econ} />}
      {locationState?.food && <LocationInfoAccordion title={`Food & Fun`} content={locationState?.food} />}
      {locationState?.history && <LocationInfoAccordion title={`History & Religion`} content={locationState?.history} />}
    </Flex>
  );
}

const LocationInfoAccordion = ({title, content}) => (
  <Accordion allowToggle>
    <AccordionItem>
      <AccordionButton
        _hover={{
          backgroundColor: 'whiteAlpha.300'
        }}
      >
        <Box flex='1' textAlign='left' marginY="2">
          <Text
            as="h4"
            fontSize={'sm'}
            textTransform="uppercase"
            textColor={'white'}
            fontWeight="bold"
          >
            {title}
          </Text>
        </Box>
        <AccordionIcon textColor={'white'} />
      </AccordionButton>
      <AccordionPanel pb={4} textColor={'white'}>
        <div dangerouslySetInnerHTML={{__html: content?.replaceAll('<p', '<p style="color:white;"')}} />
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
)

export default LocationInfo;
