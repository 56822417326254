import Script from "next/script";

const FundraisingShirtScript = () => {
  return (<>
    <div id='product-component-1660714804798' style={{'width': '100%'}}></div>
    <Script id="shopify" strategy="lazyOnload">
      {
        `
          (function () {
            var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
            if (window.ShopifyBuy) {
              if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
              } else {
                loadScript();
              }
            } else {
              loadScript();
            }

            function loadScript() {
              var script = document.createElement('script');
              script.async = true;
              script.src = scriptURL;
              (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
              script.onload = ShopifyBuyInit;
            }

            function ShopifyBuyInit() {
              var client = ShopifyBuy.buildClient({
                domain: 'ppmgear.myshopify.com',
                storefrontAccessToken: '89adf3b831f1de253dd306496ff6d24d',
              });
              
              ShopifyBuy.UI.onReady(client).then(function (ui) {
                ui.createComponent('product', {
                  id: '7217408802965',
                  node: document.getElementById('product-component-1660714804798'),
                  moneyFormat: '%24%7B%7Bamount%7D%7D',
                  options: {
                    "product": {
                      "styles": {
                        "product": {
                          "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0",
                            "margin-bottom": "50px"
                          },
                          "text-align": "left"
                        },
                        "title": {
                          "font-family": "Droid Sans, sans-serif",
                          "font-weight": "normal",
                          "font-size": "26px"
                        },
                        "button": {
                          ":hover": {
                            "background-color": "#4DAE9B"
                          },
                          "background-color": "#55C1AC",
                          ":focus": {
                            "background-color": "#4DAE9B"
                          }
                        },
                        "price": {
                          "font-family": "Droid Sans, sans-serif",
                          "font-size": "18px"
                        },
                        "compareAt": {
                          "font-family": "Droid Sans, sans-serif",
                          "font-size": "15.299999999999999px"
                        },
                        "unitPrice": {
                          "font-family": "Droid Sans, sans-serif",
                          "font-size": "15.299999999999999px"
                        }
                      },
                      "layout": "horizontal",
                      "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true,
                        "description": true
                      },
                      "width": "100%",
                      "text": {
                        "button": "Add to cart"
                      },
                      "googleFonts": [
                        "Droid Sans"
                      ]
                    },
                    "productSet": {
                      "styles": {
                        "products": {
                          "@media (min-width: 601px)": {
                            "margin-left": "-20px"
                          }
                        }
                      }
                    },
                    "modalProduct": {
                      "contents": {
                        "img": false,
                        "imgWithCarousel": true,
                        "button": false,
                        "buttonWithQuantity": true
                      },
                      "styles": {
                        "product": {
                          "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0px",
                            "margin-bottom": "0px"
                          }
                        },
                        "button": {
                          ":hover": {
                            "background-color": "#4DAE9B"
                          },
                          "background-color": "#55C1AC",
                          ":focus": {
                            "background-color": "#4DAE9B"
                          }
                        },
                        "title": {
                          "font-family": "Helvetica Neue, sans-serif",
                          "font-weight": "bold",
                          "font-size": "26px",
                          "color": "#4C4C4C"
                        },
                        "price": {
                          "font-family": "Helvetica Neue, sans-serif",
                          "font-weight": "normal",
                          "font-size": "18px",
                          "color": "#4C4C4C"
                        },
                        "compareAt": {
                          "font-family": "Helvetica Neue, sans-serif",
                          "font-weight": "normal",
                          "font-size": "15.299999999999999px",
                          "color": "#4C4C4C"
                        },
                        "unitPrice": {
                          "font-family": "Helvetica Neue, sans-serif",
                          "font-weight": "normal",
                          "font-size": "15.299999999999999px",
                          "color": "#4C4C4C"
                        }
                      },
                      "text": {
                        "button": "Add to cart"
                      }
                    },
                    "option": {
                      "styles": {
                        "label": {
                          "font-family": "Droid Sans, sans-serif"
                        },
                        "select": {
                          "font-family": "Droid Sans, sans-serif"
                        }
                      },
                      "googleFonts": [
                        "Droid Sans"
                      ]
                    },
                    "cart": {
                      "styles": {
                        "button": {
                          ":hover": {
                            "background-color": "#4DAE9B"
                          },
                          "background-color": "#55C1AC",
                          ":focus": {
                            "background-color": "#4DAE9B"
                          }
                        }
                      },
                      "text": {
                        "total": "Subtotal",
                        "notice": "",
                        "button": "Checkout"
                      }
                    },
                    "toggle": {
                      "styles": {
                        "toggle": {
                          "background-color": "#55C1AC",
                          ":hover": {
                            "background-color": "#4DAE9B"
                          },
                          ":focus": {
                            "background-color": "#4DAE9B"
                          }
                        }
                      }
                    }
                  },
                });
              });
            }
          })();
        `
      }
    </Script>
  </>
  )
}

export default FundraisingShirtScript;