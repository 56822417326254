import { FormLabel } from "@chakra-ui/react";
interface ChurchChampionFormLabel {
  forField: "firstname" | "lastname" | "address" | "address1" | "address2" | 
            "city" | "state" | "zipcode" | "country" | "dob" | "dobDay" | "dobMonth" | "dobYear" | "phone" | "email" | 
            "guardianFirst" | "guardianLast" | "guardianPhone" | "isRegistered" | "groupName" | "tripLocation" | "tripDate" | "isChurchChampion";
  label: string;
  color: string;
}
const ChurchChampionFormLabel = ({ label, forField, color }: ChurchChampionFormLabel) => (
  <FormLabel
    htmlFor={forField}
    color={color}
    textAlign={{ base: 'left', md: 'right' }}
    mb={0}
    mt={'6px'}
    fontWeight={'bold'}
    fontSize="sm"
  >
    {label}
  </FormLabel>
)
export default ChurchChampionFormLabel;