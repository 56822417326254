import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { type } from "os";
import { useState, useEffect } from "react";
import { getCookie } from "../../utils/misc";
import { DynamicComponentProps } from "../DynamicComponent"
import SignInModals from "./SignInModals";


interface SbSignInButtonProps extends DynamicComponentProps {

}

const SbSignInButton = ({ blok, globalState, setGlobalState }: SbSignInButtonProps): JSX.Element => {
    const signInModal = useDisclosure();
    const signOutModal = useDisclosure();
    const resetPasswordModal = useDisclosure();
    const alertModal = useDisclosure();


    return (
        <>
            <SignInModals
                globalState={globalState}
                setGlobalState={setGlobalState}
                alertModal={alertModal}
                resetPasswordModal={resetPasswordModal}
                signInModal={signInModal}
                signOutModal={signOutModal}
            ></SignInModals>
            <Button
                onClick={signInModal.onOpen}
                colorScheme="brand.green"
            >
                {blok.text}
            </Button>

        </>
    )
}

export default SbSignInButton