import { Box } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import PpmMap from "../maps/PpmMap"
import { createMapData } from "../../utils/maps"
import { useMemo } from "react"
import DisasterMapInfoWindow from "./DisasterMapInfoWindow"
import { useElementSize } from "usehooks-ts"


interface DisasterResposneProps extends AllPageProps {
    blok: any
}

const DisasterResponseMap = (props: DisasterResposneProps): JSX.Element => {

    const responses = props.globalState.disasters.filter(d => d.active === 1)


    const mapData = useMemo(
        () => createMapData(responses.map(r => {return {...r, name: r.title}}))
        ,[responses])

    if (!props.blok.height) props.blok.height = "100%"

    return (
        <Box
            {...storyblokEditable(props.blok)}
            borderRadius={"xl"}
            overflow={"visible"}
            width={`${props.blok.width}%`} minHeight={"250px"} height={props.blok.height}>
            <PpmMap<DisasterResponse> allowMarkerClick={true} mapData={mapData}  SingleInfoWindow={DisasterMapInfoWindow} />
        </Box>

    )
}

export default DisasterResponseMap