import { LinkBox, LinkOverlay, Flex, Button, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import Image from "next/legacy/image";




const DisasterMapInfoWindow = (props: SingleMapInfoProps<DisasterResponse>) => {
    let photoUrl;
    const disaster = props.data
  
    if (disaster.photoSet) {
      photoUrl = `https://images.ppm.org/upload/disasterphotos/thumb/${disaster.url}.jpg?v=${disaster.photoVersion}`;
    }
    else {
      photoUrl = `/images/praying-pelican-logo-globe.png`;
    }
  
    return <>
      <LinkBox
        bg="white"
        borderWidth="1px"
        boxShadow="lg"
        overflow="hidden"
        cursor={'pointer'}
        width="250px"
        rounded="xl"
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -200px)',
          cursor: 'pointer',
          zIndex: 1000
        }}
      >
        <Link href={`/response/${disaster.url}`} passHref legacyBehavior>
          <LinkOverlay
            sx={{
              '&:hover': {
                textDecoration: "none",
              }
            }}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              h="200px"
            >
              <Image
                src={photoUrl}
                alt={disaster.title}
                width={disaster.photoSet ? '250' : '130'}
                height={'130'}
                objectFit={'cover'}
              />
              <Flex
                p="2"
                direction="column"
                justifyContent={'center'}
                flexGrow="1"
              >
                <Text as="h3" fontSize="sm" textAlign={'center'} fontWeight='bold' lineHeight={1}>
                  {disaster.title}
                </Text>
                <Text
                  as="p"
                  textAlign={'center'}
                  mb="0"

                >
                  <em>{disaster.theLocationName}</em>
                </Text>
                <Button
                  size="2xs"
                  padding="1"
                  colorScheme="brand.green"
                  mt="1"
                  rightIcon={<FontAwesomeIcon icon="chevron-right" />}
                >View Response Page</Button>
              </Flex>
            </Flex>
          </LinkOverlay>
        </Link>
      </LinkBox>
      <div className="bottom-arrow"></div>
    </>;
  }

  export default DisasterMapInfoWindow