import { Box } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { colorToVar } from "../../utils/misc";

const Line = ({ blok }) => (
  <Box
    my={{ base: blok.mobileHeight, lg: blok.height }}
    w="full"
    {...storyblokEditable(blok)}
  > 
    <hr
      style={{
        borderColor: colorToVar(blok.color, blok.saturation)
      }}
    />
  </Box>
);

export default Line;
