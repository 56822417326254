import React, { useContext } from 'react';
import {
  Button,
  Divider,
  Flex,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import JournalComment from './JournalComment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JournalCommentModal from './JournalCommentModal';
import { storyblokEditable } from '@storyblok/react';

interface Props {
  blok: any;
  globalState: GlobalState;
  setGlobalState: React.Dispatch<React.SetStateAction<GlobalState>>;
}

const TripJournalComments = ({ blok, globalState, setGlobalState}:Props):JSX.Element => {
  const commentModal = useDisclosure();

  if (globalState.journalData.TRIPINFO.beforeTripDate === 1) return null;

  return (
    <>
      <Flex 
        {...storyblokEditable(blok)}
        direction="column"
        w="full"
        gap="3"
        mt="5"
      >
        <Text
          as="h4"
          fontWeight={'bold'}
          my="md"
        >
          <FontAwesomeIcon icon={['fas', 'comments']} style={{'marginRight': 4}} />
          Comments
        </Text>

        <Button
          leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />} 
          colorScheme={'brand.green'}
          onClick={commentModal.onOpen}
        >
          Add Comment
        </Button>
        
        <VStack
          gap="3"
        >
          {
            globalState.journalData?.COMMENTLIST.length == 0 &&
            <Text as="p">No comments have been posted.</Text>
          }
          {
            globalState.journalData?.COMMENTLIST.map((comment, index) => (
              <JournalComment key={index} {...comment} />
            ))
          }
        </VStack>
      </Flex>
      
      <JournalCommentModal 
        tripId={globalState.journalTripId} 
        isOpen={commentModal.isOpen} 
        onClose={commentModal.onClose}
        globalState={globalState}
        setGlobalState={setGlobalState}
      />
    </>
  );
}

export default TripJournalComments;