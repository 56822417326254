import { Divider, Text, VStack } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Ppm365Update from "./Ppm365Update";

interface Location365UpdatesProps {
  blok: any;
  globalState: GlobalState;
  ppm365: {location: RootsLocation, updates: Ppm365Update[]};
}

const Location365Updates = ({ blok, globalState, ppm365 }: Location365UpdatesProps):JSX.Element => {
  return (
    <VStack
      {...storyblokEditable(blok)}
      gap="6"
      divider={
        <Divider
          borderColor={'brand.darkgray.200'}
          sx={{ marginBottom: '-10px!important' }}
        />
      }
    >
      {
        ppm365.updates.length == 0 &&
        <Text as="p" fontSize="xl" fontWeight={'bold'}>
          No updates have been posted at this time. Check back soon!
        </Text>
      }

      {
        ppm365.updates.map((entry, index) => (
          <Ppm365Update
            {...entry}
            key={index}
            photoUrl={
              entry.photo === 0
                ? ''
                : `https://images.ppm.org/upload/365/${entry.journalID}.jpg`
            }
          />
        ))
      }
    </VStack>
  );
}

export default Location365Updates;