import { useContext } from "react"
import { PartnerProfileContext } from "../../contexts/ppm365"
import Text from "../../layout/Text"

export interface PartnerHeaderProps {
    blok: any
}

const PartnerText = (props: PartnerHeaderProps): JSX.Element => {
    const profile = useContext(PartnerProfileContext)

    const blok = props.blok

    if (blok.contentField === "pastorFullName") {
        blok.content = `${profile.pastorFirstName} ${profile.pastorLastName}`
    }
    else {
        blok.content = (profile as any)[blok.contentField]
    }

    return (
        <Text blok={blok}></Text>
    )
}

export default PartnerText