import { storyblokEditable } from "@storyblok/react";
import FundraisingShirtScript from "../../scripts/ShopifyFundraisingShirt";


const FundraisingShirt = ({blok}) => {
  return (
    <FundraisingShirtScript {...storyblokEditable(blok)} />
  )
}

export default FundraisingShirt;