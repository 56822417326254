import { storyblokEditable } from "@storyblok/react";
import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TripPricingTable = ({ blok }) => {
  return (
    <TableContainer
      {...storyblokEditable(blok)}
      borderRadius="xl"
    >
      <Table>
        <Thead
          color="whiteAlpha.800"
          backgroundColor="brand.green.600"
        >
          <Tr
            _hover={{
              backgroundColor: 'blackAlpha.100'
            }}
          >
            <Th textAlign="center" borderBottomColor="brand.darkgray.600" borderRightWidth="1px" borderRightColor={'brand.darkgray.600'} maxWidth="sm">
              <Text as="h2" color="white" fontWeight="bold" >
                {blok.title}
              </Text>
            </Th>
            {
              blok.packages.map((tripPackage, index) => (
                <Th
                  key={index}
                  textAlign="center"
                  fontWeight="bold"
                  borderColor="brand.darkgray.600"
                  backgroundColor="blackAlpha.200"
                  borderRight={index === blok.packages.length - 1 ? '0px' : '1px'}
                  borderRightColor="brand.darkgray.600"
                  maxW="md"
                >
                  <Text as="h3" color="white" mb="2">
                    {tripPackage.content?.title}
                  </Text>
                  <Text as="h5" color="white" mb="0">
                    {tripPackage.content?.subtitle}
                  </Text>
                </Th>
              ))
            }
          </Tr>
        </Thead>
        <Tbody
          color="whiteAlpha.800"
          backgroundColor="brand.darkgray.700"
        >
          {!blok.hideDuration && <DurationRow packages={blok.packages} />}
          {!blok.hideRegiFee && <RegiFeeRow packages={blok.packages} />}
          {!blok.hideMaterialsProvided && <MaterialsRow packages={blok.packages} property={'materialsProvided'} />}
          {!blok.hideConsultation && <TextCheckboxRow text={'Unlimited Consultation'} packages={blok.packages} property={'unlimitedConsultation'} />}
          {!blok.hideMinistryHours && <TextCheckboxRow text={'35+ Hours of Ministry'} packages={blok.packages} property={'ministryIncluded'} />}
          {!blok.hideTransportation && <TextCheckboxRow text={'Transportation to/from Airport and Ministry Sites'} packages={blok.packages} property={'transporationIncluded'} />}
          {!blok.hideChurch && <TextCheckboxRow text={'Participation in Local Church Services'} packages={blok.packages} property={'churchServices'} />}
          {!blok.hideCustomizable && <TextCheckboxRow text={'Customizable'} packages={blok.packages} property={'customizable'} />}
          {!blok.hideSleepingAccommodations && <AccommodationsRow packages={blok.packages} />}
          {!blok.hideMeals && <MealsRow packages={blok.packages} />}
          {!blok.hideRecreation && <RecreationRow packages={blok.packages} />}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

const DurationRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Duration
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.content?.duration}D/{tripPackage.content?.duration - 1}N
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const RegiFeeRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Group Registration Fee
      </Text>
      <Text as="p" color="white" mb="0" fontSize="sm">
        Not included in participant cost
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          ${tripPackage.content?.registrationFee}
        </PackageColumn>

      ))
    }
  </PackageRow>
)

const MaterialsRow = ({ packages, property }) => (
  <CheckboxRow packages={packages} property={property}>
    <Text as="p" color="white" fontWeight="bold" mb="0">
      Materials Provided
    </Text>

    <ul>
      <li>Online mission trip organizing toolbox</li>
      <li>Digital leader handbook</li>
      <li>Participant devotionals and guides</li>
      <li>Trip promotional materials</li>
      <li>Fundraising Ideas</li>
    </ul>
  </CheckboxRow>
)

const RecreationRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Recreation or Local Experience
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.content?.recreation}
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const AccommodationsRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Sleeping Accommodations
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.content?.sleepingAccomodations}
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const MealsRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Meals
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.content?.meals}
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const TextCheckboxRow = ({ packages, text, property }) => (
  <CheckboxRow packages={packages} property={property}>
    <Text as="p" color="white" fontWeight="bold" mb="0">
      {text}
    </Text>
  </CheckboxRow>
)

const CheckboxRow = ({ packages, children, property }) => (
  <PackageRow>
    <PackageInitialColumn>
      {children}
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <CheckboxColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'} checked={tripPackage.content && tripPackage.content[property]} />
      ))
    }
  </PackageRow>
)

const CheckboxColumn = ({ borderRight, checked }) => (
  <PackageColumn borderRight={borderRight}>
    {checked ? <FontAwesomeIcon icon={['fas', 'check']} /> : 'Not Included'}
  </PackageColumn>
)

const PackageRow = ({ children }) => (
  <Tr _hover={{ backgroundColor: 'blackAlpha.200' }}>
    {children}
  </Tr>
)

const PackageInitialColumn = ({ children }) => (
  <Td borderColor="brand.darkgray.600" borderRightWidth="1px" maxWidth="lg" whiteSpace="normal">
    {children}
  </Td>
)

const PackageColumn = ({ children, borderRight }) => (
  <Td
    textAlign="center"
    fontWeight="bold"
    borderColor="brand.darkgray.600"
    borderRight={borderRight}
    borderRightColor="brand.darkgray.600"
    maxW="lg"
  >
    <Text as="p" color="whiteAlpha.800" mb="0" whiteSpace="normal">
      {children}
    </Text>
  </Td>
)

export default TripPricingTable;
