import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, VisuallyHidden } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import AlertModal from "../misc/AlertModal";

interface Props {
  submitHandler: (values: any) => Promise<void>;
  alertModal: {
    modalData: AlertModalProps;
    confirmModal: {
      isOpen: boolean;
      onOpen: () => void;
      onClose: () => void;
      onToggle: () => void;
      isControlled: boolean;
      getButtonProps: (props?: any) => any;
      getDisclosureProps: (props?: any) => any;
    }
  }
}

const SubscribeForm = ({ submitHandler, alertModal }: Props):JSX.Element => {
  const { register, handleSubmit, formState } = useForm<{ email: string, subscribe: number }>({
    defaultValues: {
      email: ''
    }
  });

  return (
    <>
      <AlertModal
        onClose={alertModal.confirmModal.onClose}
        isOpen={alertModal.confirmModal.isOpen}
        iconColor={alertModal.modalData.iconColor}
        icon={alertModal.modalData.icon}
        title={alertModal.modalData.title}
        content={alertModal.modalData.content}
      />
      <form onSubmit={handleSubmit(submitHandler)}>
        <FormControl
          borderRadius={'lg'}
          isInvalid={!!formState.errors['email']}
          isDisabled={formState.isSubmitting}
        >
          <VisuallyHidden>
            <FormLabel htmlFor={'email'}>
              Email
            </FormLabel>
          </VisuallyHidden>

          <Input
            name={'email'}
            type={'email'}
            variant="outline"
            size={'md'}
            layerStyle={'lightForm'}
            placeholder={'Email Address'}
            {...register('email', { required: 'Email is required' })}
            border="1px solid"
            borderColor={'brand.orange.600'}
          />

          <FormErrorMessage>
            <Box textAlign="center" w="full">
              {formState.errors['email']?.message}
            </Box>
          </FormErrorMessage>
        </FormControl>

        <Button
          type="submit"
          width="full"
          colorScheme={'brand.orange'}
          variant={'solid'}
          leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
          disabled={formState.isSubmitting}
          mt={3}
        >
          SUBSCRIBE
        </Button>
      </form>
    </>
  )
}

export default SubscribeForm;