import { storyblokEditable } from "@storyblok/react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MinistryOptionCard = ({ blok }) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...storyblokEditable(blok)} 
    >
      <Box color={`${blok.iconColor}.600`} fontSize="4xl">
        {
          blok.ministry.content?.icon && blok.ministry.content?.icon.icon !== '' ?
          <FontAwesomeIcon color={`${blok.iconColor}.600`} icon={[blok.ministry.content?.icon.type, blok.ministry.content?.icon.icon]} /> :
          <FontAwesomeIcon color={`${blok.iconColor}.600`} icon={['fas', 'question-circle']} />
        }
      </Box>
      <Text fontWeight={'bold'} color={`${blok.textColor}.600`}>{blok.ministry.content?.title}</Text>
      <Text color={`${blok.textColor}.600`}>{blok.ministry.content?.description}</Text>
    </Flex>
  )
};

export default MinistryOptionCard;
