import { Divider, Text, VStack } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Ppm365Update from "./Ppm365Update";

interface MainPpm365UpdatesProps {
  blok: any;
  globalState: GlobalState;
}

const MainPpm365Updates = ({ blok, globalState }: MainPpm365UpdatesProps):JSX.Element => {
  const updates = blok.limit > 0 ? globalState.ppm365Updates.slice(0, blok.limit) : globalState.ppm365Updates;

  if (!updates) return null;

  return (
    <VStack
      {...storyblokEditable(blok)}
      gap="6"
      divider={
        <Divider
          borderColor={'brand.darkgray.200'}
          sx={{ marginBottom: '-10px!important' }}
        />
      }
    >
      {
        updates.length == 0 &&
        <Text as="p" fontSize="xl" fontWeight={'bold'}>
          No updates have been posted at this time. Check back soon!
        </Text>
      }

      {
        updates.map((entry, index) => (
          <Ppm365Update
            {...entry}
            key={index}
            photoUrl={
              entry.photo === 0
                ? ''
                : `https://images.ppm.org/upload/365/${entry.journalID}.jpg`
            }
          />
        ))
      }
    </VStack>
  );
}

export default MainPpm365Updates;