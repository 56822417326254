import { Box, Text } from "@chakra-ui/react";


interface JournalCommentProps {
  author: string;
  commentDate: string;
  entry: string;
  journalID: number;
}

const JournalComment = (props:JournalCommentProps):JSX.Element => {
  return (
    <Box
      w="full"
      p="5"
      borderWidth="1px"
      borderColor="brand.orange.400"
      borderRadius="lg"
    >
      <Text fontWeight={'bold'} mb="0">{props.author}</Text>
      <Text fontSize={'xs'}>{props.commentDate}</Text>
      <Text>{props.entry}</Text>
    </Box>
  )
}

export default JournalComment;