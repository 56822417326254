import { storyblokEditable } from "@storyblok/react";
import { Flex, Text } from "@chakra-ui/react";
import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import React from "react";

interface LocationFaqsProps  extends DynamicComponentProps<any>{
  locationState: RootsLocation;
}


const LocationFaqs = ({ blok, globalState, setGlobalState, locationState, locale }:LocationFaqsProps):JSX.Element => {
  return (
    <Flex
      {...storyblokEditable(blok)}
      direction={'column'}
      w="full"
      p="10"
    >
      <Text as="h2" color="white" mb="5">
        {locationState?.name} FAQs
      </Text>
      
      {blok.content
        ? blok.content.map((blok) => (
          <DynamicComponent blok={blok} key={blok._uid} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} locale={locale}/>
        ))
        : null}
    </Flex>
  );
}

export default LocationFaqs;
