import { Button, FormControl, FormErrorMessage, FormLabel, Input, VisuallyHidden, Box, Text, useDisclosure } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AlertModal from "../misc/AlertModal";
import Main365SubscribeFormLocationCheckboxes from "./Main365SubscribeFormLocationCheckboxes";

interface MainPpm365SubscribeProps {
  blok: any;
  globalState: GlobalState;
}

const MainPpm365Subscribe = ({ blok, globalState }: MainPpm365SubscribeProps):JSX.Element => {
  const locations = globalState.locations.filter(location => {
    return location.rootsData.landing365 === 1;
  });

  const [modalData, setModalData] = useState<AlertModalProps>({
    icon: ['far', 'circle-check'] as IconProp,
    iconColor: 'brand.green.600',
    title: 'Got It!',
    content: `You're now subscribed to receive updates when new posts are made.`
  });

  const confirmModal = useDisclosure();

  if (!locations) return null;

  const submitSubscribeForm = async (values: Main365SubscribeFormProps) => {
    const submitData = { email: values.email, locationArray: values.locationArray };

    try {
      const sendForm = await fetch(`${process.env.NEXT_PUBLIC_PPMAPI_URL}/home/ppm365/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(submitData)
      })

      if (sendForm.ok) {
        setModalData({
          icon: ['far', 'circle-check'] as IconProp,
          iconColor: 'brand.green.600',
          title: 'Got It!',
          content: `You're now subscribed to receive updates when new posts are made.`
        });

        confirmModal.onOpen();

      } else {
        console.error(sendForm);
        throw new Error('Failed to send subscription form')
      }

      confirmModal.onOpen();

    } catch (error) {
      console.error(error);
      setModalData({
        icon: ['fas', 'xmark'] as IconProp,
        iconColor: 'red.400',
        title: 'Oops!',
        content: 'Sorry, but there was an error submitting your information. Please try again later.'
      });

      confirmModal.onOpen();
    }
  }

  return (
    <Box
      w="full"
      p="5"
      bgColor="brand.green.50"
      borderRadius={'xl'}
      textAlign="center"
      borderColor={'brand.green.400'}
      borderWidth="4px"
      {...storyblokEditable(blok)}
    >
      <Text as="h4" color="brand.darkgray.600" fontWeight={'bold'} textTransform={'uppercase'} mb="3">Subscribe To PPM365 Updates</Text>
      <SubscribeFormWithLocations submitHandler={submitSubscribeForm} alertModal={{ modalData, confirmModal }} locations={locations} />
    </Box>
  )
}

export interface Main365SubscribeFormProps {
  email: string;
  locationArray: string[];
}

const SubscribeFormWithLocations = ({ submitHandler, alertModal, locations }:{submitHandler: any; alertModal: any; locations:StoryblokLocation[]}):JSX.Element => {
  const { register, handleSubmit, formState } = useForm<Main365SubscribeFormProps>({
    defaultValues: {
      email: '',
      locationArray: []
    }
  });

  return (
    <>
      <AlertModal
        onClose={alertModal.confirmModal.onClose}
        isOpen={alertModal.confirmModal.isOpen}
        iconColor={alertModal.modalData.iconColor}
        icon={alertModal.modalData.icon}
        title={alertModal.modalData.title}
        content={alertModal.modalData.content}
      />
      <form onSubmit={handleSubmit(submitHandler)}>
        <FormControl
          borderRadius={'lg'}
          isInvalid={!!formState.errors['email']}
          isDisabled={formState.isSubmitting}
        >
          <VisuallyHidden>
            <FormLabel htmlFor={'email'}>
              Email
            </FormLabel>
          </VisuallyHidden>

          <Input
            name={'email'}
            type={'email'}
            variant="outline"
            size={'md'}
            layerStyle={'lightForm'}
            placeholder={'Email Address'}
            {...register('email', { required: 'Email is required' })}
            border="1px solid"
            borderColor={'brand.orange.600'}
          />

          <FormErrorMessage>
            <Box textAlign="center" w="full">
              {formState.errors['email']?.message}
            </Box>
          </FormErrorMessage>
        </FormControl>

        <Main365SubscribeFormLocationCheckboxes
          heading="CHOOSE PPM365 LOCATIONS"
          colorScheme={'light'}
          locations={locations}
          register={register}
          submitting={formState.isSubmitting}
        />

        <Button
          type="submit"
          width="full"
          colorScheme={'brand.orange'}
          variant={'solid'}
          leftIcon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
          disabled={formState.isSubmitting}
          mt={3}
        >
          SUBSCRIBE
        </Button>
      </form>
    </>
  )
}

export default MainPpm365Subscribe;