import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import { storyblokEditable } from "@storyblok/react";
import { Flex, LinkBox, Link, LinkOverlay } from "@chakra-ui/react";
import { convertColor, getRealLink } from "../../utils/misc";
import { ConditionalWrapper } from "../misc/ConditionalWrapper";


type SignInVisibility = "everyone" | "signedIn" | "notSignedIn"

export interface BoxProps extends DynamicComponentProps<BoxBlok> {

}

const Box = ({ blok, globalState, setGlobalState, locationState, ppm365, locale }: BoxProps) => {
  const horizontalAlign = blok.alignment === 'center' ? 'center' : blok.alignment === 'left' ? 'flex-start' : 'flex-end';
  const verticalAlign = blok.verticalAlignment === 'middle' ? 'center' : blok.verticalAlignment === 'top' ? 'flex-start' : 'flex-end';
  const visibility: SignInVisibility = blok.signInVisibility ? blok.signInVisibility : "everyone" //default to visible
  let linkHref = ""
  let linkTarget = ""


  const bgColor = convertColor(blok.backgroundColor, blok.backgroundSaturation)
  const borderColor = convertColor(blok.borderColor, blok.borderSaturation)

  if (blok.link) {
    [linkHref, linkTarget] = getRealLink(blok.link, blok.openInNewWindow)
  }
  return (
    (
      (visibility === "everyone") ||
      (visibility === "signedIn" && globalState.user?.email) ||
      (visibility === "notSignedIn" && !globalState.user?.email)
    ) &&
    <ConditionalWrapper condition={blok.link} wrapper={children => <LinkBox
      h="full"
    >{children}
    </LinkBox>}
    >
      <Flex
        bgColor={bgColor}
      >
        <Flex
          {...storyblokEditable(blok)}
          border={`solid`}
          borderWidth={blok.borderWidth ? blok.borderWidth : 0}
          borderColor={borderColor}
          borderRadius={blok.borderRadius}
          shadow={blok.shadow}
          w={blok.width ? blok.width : 'full'}
          maxW={blok.maxWidth}
          pointerEvents="all"
          flexDirection="column"
          mt={blok.marginTop}
          mb={blok.marginBottom}
          px={blok.paddingX}
          py={blok.paddingY}
          alignItems={horizontalAlign}
          justifyContent={verticalAlign}
          overflow="hidden"
          _hover={blok.link ? {
            bgColor: 'blackAlpha.300'
          } : {}}
        >

          <ConditionalWrapper condition={blok.link} wrapper={children => <LinkOverlay href={linkHref} target={linkTarget} _hover={{ textDecoration: "none" }}   >
            {children}</LinkOverlay>}>

            {blok.anchor && <a id={blok.anchor} style={{ position: 'relative', top: '-40px' }}></a>}
            {
              blok.content?.map((nestedBlok, index) => (
                <DynamicComponent
                  blok={nestedBlok}
                  key={index}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  locationState={locationState}
                  ppm365={ppm365}
                  locale={locale}
                />
              ))
            }
          </ConditionalWrapper>
        </Flex>
      </Flex>
    </ConditionalWrapper>
  );
}

export default Box;
