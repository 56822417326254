import { useEffect, useState } from "react";
import { storyblokInit, apiPlugin, getStoryblokApi, StoryblokResult } from "@storyblok/react";
import { getPastDisasterResponseList, getReadinessTrips, getRecentDisasterResponseUpdates } from "./ppmApi";


const resolveRelations = [
  'testimonialCard.testimonial',
  'locationCards.locations',
  'ministryOptionCard.ministry',
  'faqAccordion.faq',
  'buttonVideo.video',
  'locationLandingPage.location',
  'responsiveVideoEmbed.video',
  'tripDateList.location',
  'tripPricingTable.packages'
];

export const defaultStoryblokOptions = {
  version: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? "published" : "draft",
  cv: process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? Date.now() : Date.now(),
  resolve_relations: resolveRelations
};

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
});

const Storyblok = getStoryblokApi();

export function useStoryblok(originalStory, locale) {
  let [story, setStory] = useState(originalStory);

  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  function initEventListeners() {
    const { StoryblokBridge } = window;
    if (typeof StoryblokBridge !== "undefined") {
      // initialize the bridge with your token
      const storyblokInstance = new StoryblokBridge({
        language: locale,
        resolveRelations: resolveRelations
      });

      // reload on Next.js page on save or publish event in the Visual Editor
      storyblokInstance.on(["change", "published"], () =>
        location.reload()
      );

      // live update the story on input events
      storyblokInstance.on("input", (event) => {
        if (story && event.story.uuid === story.uuid) {
          setStory(event.story);
        }
      });

      storyblokInstance.on("enterEditmode", (event) => {
        // loading the draft version on initial enter of editor
        Storyblok.get(`cdn/stories/${event.storyId}`, {
          version: "draft",
          language: locale,
        })
          .then(({ data }) => {
            if (data.story) {
              setStory(data.story);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }

  // appends the bridge script tag to our document
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
  function addBridge(callback) {
    // check if the script is already present
    const existingScript = document.getElementById("storyblokBridge");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
      script.id = "storyblokBridge";
      document.body.appendChild(script);
      script.onload = () => {
        // once the scrip is loaded, init the event listeners
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    // only load inside preview mode
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production') {
      // first load the bridge, then initialize the event listeners
      addBridge(initEventListeners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStory(originalStory);
  }, [originalStory]);

  return [story, setStory];
}




export async function getComponentProps(storyResult: StoryblokResult, locale: string): Promise<ComponentSpecificProps> {

    let returnObj: ComponentSpecificProps = {}

    const storyBody = storyResult.data?.story?.content?.body ?? []

    if (checkContainerForComponent(storyBody, "disasterResponseUpdates")) {

      returnObj = { ...returnObj, recentDisasterUpdates: await getRecentDisasterResponseUpdates(locale) }


    }

    //We'll technically look up past disasters even when the list is only showing current, but it's still much more efficient
    //than including it in the props for every page.
    if (checkContainerForComponent(storyBody, "disasterResponseList")) {
      returnObj = { ...returnObj, pastDisasters: await getPastDisasterResponseList(locale) }
    }

    if (checkContainerForComponent(storyBody, "readinessTripList")) {
      returnObj = { ...returnObj, readinessTrips: await getReadinessTrips(locale) }
    }

    return returnObj

}

export function checkContainerForComponent(container: any[], componentName: string) {

  let result = false



  for (const component of container) {
    if (component.content && Array.isArray(component.content)) {
      result = checkContainerForComponent(component.content, componentName)
    }

    if (component.leftContent && Array.isArray(component.leftContent)) {
      result = checkContainerForComponent(component.leftContent, componentName)
    }

    if (component.rightContent && Array.isArray(component.rightContent)) {
      result = checkContainerForComponent(component.rightContent, componentName)
    }

    if (!result) {
      result = component.component === componentName
    }

    if (result === true) {
      break;
    }
  }

  return result
}


export default Storyblok;
