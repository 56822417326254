import { storyblokEditable } from "@storyblok/react";
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Select, Text } from "@chakra-ui/react";
import { DeepRequired, FieldErrorsImpl, useForm, UseFormRegister } from "react-hook-form";
import { useEffect } from "react";
import { getTripTimeframes } from "../../utils/misc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";

interface MissionTripSearchProps {
  blok: any;
  globalState: GlobalState;
}

interface MissionTripSearchFormProps {
  timing: string;
  location: string;
  size: string;
}

interface SelectFormProps {
  name: 'size' | 'timing' | 'location';
  label: string;
  placeholder: string;
  options?: { value: string, label: string }[];
  optionGroups?: { label: string, options: {value: string, label:string}[] }[];
  register: UseFormRegister<MissionTripSearchFormProps>;
  errors: FieldErrorsImpl<DeepRequired<MissionTripSearchFormProps>>;
}

const MissionTripSearch = ({ blok, globalState }:MissionTripSearchProps):JSX.Element => {
  const locations = globalState.locations
    .sort((a, b) => {
      const aNameWithoutThe = a.name.indexOf('The ') === 0 ? a.name.replace('The ', '') : a.name;
      const bNameWithoutThe = b.name.indexOf('The ') === 0 ? b.name.replace('The ', '') : b.name;

      return aNameWithoutThe.localeCompare(bNameWithoutThe);
    })
    .map(location => ({ value: location.rootsId, label: location.name, domestic: location.rootsData.domestic }));
  
  const regions = Array.from(new Set(globalState.locations.map(trip => trip.region)))
    .sort((a, b) => a.localeCompare(b))
    .map(region => ({ value: region.replaceAll(' ', '').toLowerCase(), label: region }))
  
  const { register, handleSubmit, formState } = useForm<MissionTripSearchFormProps>({
    defaultValues: {
      timing: '',
      location: '',
      size: '',
    }
  });

  const router = useRouter();

  const getSearchResults = async (values) => {
    router.push('/mission-trip-dates?search=' + values.timing + ',' + values.location);
  }

  return (
    <Box w="full">
      <form onSubmit={handleSubmit(getSearchResults)}>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)' }}
          justifyContent="center"
          alignItems="center"
          height="full"
          w="full"
          gap="5"
        >
          <GridItem colSpan={1}>
            <SearchFormSelect
              name="location"
              label="Location"
              optionGroups={[
                { label: 'Regions', options: regions },
                { label: 'Domestic', options: locations.filter(location => location.domestic === 1) },
                { label: 'International', options: locations.filter(location => location.domestic === 0) },
              ]}
              placeholder={'Select location...'}
              register={register}
              errors={formState.errors}
            />
          </GridItem>
          
          <GridItem colSpan={1}>
            <SearchFormSelect
              name="timing"
              label="Timeframe"
              options={getTripTimeframes()}
              register={register}
              placeholder={'Select timeframe...'}
              errors={formState.errors}
            />
          </GridItem>

          <GridItem colSpan={1}>
            <SearchFormSelect
              name="size"
              label="Group Size"
              options={[
                { value: '5-14', label: '5-14 people' },
                { value: '15-24', label: '15-24 people' },
                { value: '25-49', label: '25-49 people' },
                { value: '50+', label: '50+ people' },
              ]}
              placeholder={'Select group size...'}
              register={register}
              errors={formState.errors}
            />
          </GridItem>

          <GridItem colSpan={1}>
            <Button

              type="submit"
              variant="solid"
              colorScheme="brand.darkgray"
              leftIcon={<FontAwesomeIcon icon="search" />}
              w="full"
            >
              Search
            </Button>
          </GridItem>
        </Grid>

      </form>
    </Box>
  )
}

const SearchFormSelect = ({ name, label, options, optionGroups, placeholder, register, errors }: SelectFormProps): JSX.Element => {
  return (
    <FormControl
      isInvalid={!!errors[name]}
    >
      <FormLabel
        htmlFor={name}
        color={'white'}
        textAlign={'left'}
        mb={0}
        mt={'6px'}
        fontWeight={'bold'}
        fontSize="sm"
      >
        {label}
      </FormLabel>

      <Select
        name={name}
        size="sm"
        rounded="lg"
        variant="solid"
        border={`1px solid ${!!errors[name] ? 'red' : 'white'}`}
        bg={'brand.green.600'}
        color="white"
        {...register(name, { required: true })}
        _hover={{}}
        sx={{
          _focusVisible: {
            borderColor: `${!!errors[name] ? 'red' : 'whiteAlpha.600'}`,
            boxShadow: '0 0 0 1px var(--chakra-colors-whiteAlpha-600)',
          },
        }}
      >
        <option value={''} style={{ color: 'black' }}>{placeholder}</option>
        {
          optionGroups && optionGroups.map((group) =>
            <optgroup label={group.label} key={group.label} style={{color: 'black', fontWeight: 'bold'}}>
              {
                group.options.map((option) =>
                  <option 
                    key={option.value} 
                    value={option.value} 
                    style={{ color: 'black' }}
                  >
                    {option.label}
                  </option>
                )
              }
            </optgroup>
          )
        }
        {
          options && options.map((option) =>
            <option key={option.value} value={option.value} style={{ color: 'black' }}>{option.label}</option>
          )
        }
      </Select>
      <FormErrorMessage>Field is required.</FormErrorMessage>
    </FormControl>
  )
}

export default MissionTripSearch;
