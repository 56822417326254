import { storyblokEditable } from "@storyblok/react";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fancybox from "../misc/Fancybox";

const ButtonVideo = ({ blok }) => {
  return (
    <Fancybox>
      {
        <Button
          {...storyblokEditable(blok)}
          size={blok.button.size}
          colorScheme={blok.button.colorScheme}
          variant={blok.button.variant}
          fontWeight={blok.button.fontWeight}
          lineHeight={0}
          width={blok.button.width}
          leftIcon={blok.leftIcon && blok.leftIcon.icon !== '' ? <FontAwesomeIcon icon={[blok.leftIcon.type, blok.leftIcon.icon]} /> : undefined}
          rightIcon={blok.rightIcon && blok.rightIcon.icon !== '' ? <FontAwesomeIcon icon={[blok.rightIcon.type, blok.rightIcon.icon]} /> : undefined}
          data-fancybox="video-gallery"
          data-src={`https://www.youtube.com/watch?v=${blok.video.content?.videoId}`}
        >
          {blok.button.buttonText}
        </Button>
      }
    </Fancybox>
  );
}

export default ButtonVideo;
