import { storyblokEditable } from "@storyblok/react";
import { Table, TableContainer, Tag, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

const SampleItinerary = ({ blok }) => {
  return (
    <TableContainer 
      {...storyblokEditable(blok)} 
      borderRadius="xl"
    >
      <Table>
        <Thead 
          color="whiteAlpha.800"
          backgroundColor="brand.green.600"
        >
          <Tr>
            {
              blok.itinerary.thead?.map((th, index) => {
                if (index === 0) {
                  return (
                    <Td key={th._uid} textAlign="center" borderColor="brand.darkgray.600">
                      <Tag 
                        color="red.700" 
                        textTransform="uppercase" 
                        fontWeight="bold"
                      >
                        {th.value}
                      </Tag>
                    </Td>
                  )
                } else {
                  return (<Td key={th._uid} textAlign="center" fontWeight="bold" borderColor="brand.darkgray.600">{th.value}</Td>)
                }
              })
            }
          </Tr>
        </Thead>
        <Tbody 
          color="whiteAlpha.800"
          backgroundColor="brand.darkgray.700"
        >
          {
            blok.itinerary.tbody?.map((tr) => (
              <Tr 
                key={tr._uid} 
                _hover={{
                  backgroundColor: 'blackAlpha.200'
                }}
              >
                {
                  tr.body.map((td, index) => (
                    <Td 
                      key={td._uid} 
                      textAlign="center" 
                      color={index === 0 ? 'brand.darkgray' : 'brand.darkgray.200'}
                      fontWeight={index === 0 ? 'bold' : 'normal'}
                      borderColor="brand.darkgray.600"
                    >
                      {td.value}
                    </Td>
                  ))
                }
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SampleItinerary;
