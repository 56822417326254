import { storyblokEditable } from "@storyblok/react";
import { Box, Text } from "@chakra-ui/react";
import { getBounds, GetBoundsReturn, MarkerMap } from "../../utils/maps";
import { useEffect, useState } from "react";

interface LocationCommunityMapProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
}

const LocationCommunityMap = ({ blok, globalState, locationState }: LocationCommunityMapProps):JSX.Element => {
  // only show communities that have active partners
  let filteredCommunities: RootsCommunity[];
  filteredCommunities = locationState?.communities.length > 0 ? locationState.communities.filter(community => community.hasActivePartner) : [];

  const [bounds, setBounds] = useState<GetBoundsReturn>(
    filteredCommunities.length === 0 ?
      { center: { lat: locationState.lat, lng: locationState.lng }, zoom: 4 } :
      filteredCommunities.length === 1 ?
      { center: { lat: filteredCommunities[0].lat, lng: filteredCommunities[0].lng }, zoom: 6 } :
    getBounds(filteredCommunities, 300, 300)
  );

    const [defaultProps, setDefaultProps] = useState({
      center: {
        lat: bounds.center.lat,
        lng: bounds.center.lng
      },
      zoom: bounds.zoom,
      scrollWheel: false,
      zoomControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    })
    
    useEffect(() => {
      filteredCommunities = locationState?.communities.length > 0 ? locationState.communities.filter(community => community.hasActivePartner) : [];

      setBounds(
        filteredCommunities.length === 0 ?
          { center: { lat: locationState.lat, lng: locationState.lng }, zoom: 4 } :
          filteredCommunities.length === 1 ?
          { center: { lat: filteredCommunities[0].lat, lng: filteredCommunities[0].lng }, zoom: 6 } :
        getBounds(filteredCommunities, 300, 300)
      );
    }, [locationState?.communities, locationState.lat, locationState.lng, locationState.name]);
      
    useEffect(() => {
      setDefaultProps({
        center: {
          lat: bounds.center.lat,
          lng: bounds.center.lng
        },
        zoom: bounds.zoom,
        scrollWheel: false,
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      })
    }, [bounds])

  if (locationState === null) return null;

  return (
    <Box
      {...storyblokEditable(blok)}
      w="full"
    >
      <Text
        textAlign={'center'}
        as="h4"
        color="brand.green.600"
      >
        Communities We Work In
      </Text>
      
      <Box
        height="300px"
        borderRadius="xl"
        overflow="hidden"
      >
        <MarkerMap defaultProps={defaultProps} markers={filteredCommunities} />
      </Box>
    </Box>
  );
}

export default LocationCommunityMap;
