import { useSearchParams } from "next/navigation"
import { useEffect, useState } from "react"
import ReadinessTripList from "../components/disaster-response/readinessTripList"
import { getPaCookie, getPaToken, isPaTokenExpired } from "./pelicanAccount"
import { getReadinessTrip, isUserOnReadinessTrip } from "./ppmApi"

export const useReadinessTripFromUrl = () => {
    const searchParams = useSearchParams()
    const groupingId = searchParams.get('groupingId')
    const [readinessTrip, setReadinessTrip] = useState<ReadinessTrip | undefined>(undefined)
    const [location, setLocation] = useState<LbLocation | undefined>(undefined)
    const [disaster, setDisaster] = useState<Disaster | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {

        const dataFetch = async () => {

            if (groupingId) {
                if (!readinessTrip || readinessTrip.groupingId !== groupingId) {
                    setIsLoading(true)
                    const response = await getReadinessTrip(groupingId)

                    setIsLoading(false)
                    setReadinessTrip(response.trip)
                    setLocation(response.location)
                    setDisaster(response.response)
                }

            }
            else {
                setReadinessTrip(undefined)
                setLocation(undefined)
            }
        }

      
            dataFetch()

    }, [ groupingId,  readinessTrip])

    return {readinessTrip: readinessTrip, location: location, disaster: disaster, isLoading: isLoading}
}


export const useReadinessTripStatus = (trip: ReadinessTrip | null, isSignedIn: boolean) => {
    const [isOnReadinessTrip, setIsOnReadinessTrip] = useState<boolean>(false) 
    const [tripId, setTripId] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const dataFetch = async() => {
            if (trip && isSignedIn && !isPaTokenExpired(getPaToken())) {
                setIsLoading(true)
                const response = await isUserOnReadinessTrip(trip.groupingId)
                setIsLoading(false)
                setIsOnReadinessTrip(response.isOnTrip)
                setTripId(response.tripId)
            }
        }


        dataFetch()
    }, [trip, isSignedIn])


    return {isOnReadinessTrip, tripId, isLoading}

}