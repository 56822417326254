import { useContext } from "react"
import { PartnerProfileContext } from "../../contexts/ppm365"
import { DynamicComponentProps } from "../../DynamicComponent"
import RichText from "../../layout/RichText"

export interface PartnerRichTextProps extends DynamicComponentProps {

}

const PartnerRichText = ({blok, ...remainingProps}: DynamicComponentProps): JSX.Element => {

    const profile = useContext(PartnerProfileContext)

    const textResolver = (text) => {
            return text.replaceAll("[pastorFullName]", `${profile.pastorFirstName} ${profile.pastorLastName}`)
            .replaceAll("[pastorFirstName]", profile.pastorFirstName)
            .replaceAll("[pastorTitle]", profile.pastorTitle)
            .replaceAll("[churchName]", profile.churchName)
            .replaceAll("[churchDescription]", profile.churchDescription)
            .replaceAll("[pastorDescription]", profile.pastorDescription)
            .replaceAll("[location]", profile.location)
            .replaceAll("[pastorLastName]", profile.pastorLastName)
    }


    return (
        <RichText blok={blok} textResolver={textResolver} {...remainingProps}></RichText>
    )
}

export default PartnerRichText