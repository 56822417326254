import { DateTime } from "luxon";

export type ServerDateTypes = "utc" | "central" | "local"

class DateUtils {
    static topOfHour(d: Date) {
        return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), 0, 0, 0);
    }

    static getAge(dateString: string | number | Date) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    static parseServerDate(isoDate: string, type: ServerDateTypes) {

        switch (type) {
            case "utc":
                return DateTime.fromISO(isoDate)
            case "central":
                return DateTime.fromISO(isoDate, { setZone: true }).setZone("America/Chicago", { keepLocalTime: true })
            case "local":
                return DateTime.fromISO(isoDate, { setZone: true }).setZone("local", { keepLocalTime: true })
        }

    }

    static toShortDateString(date: Date) {
        return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd")
    }

    static getMaxBirthdayForAge(age: number) {
        return DateTime.now().minus({years: age}).startOf("day")
    }

    static isValid(dateString: string, format="yyyy-MM-dd") {
        return DateTime.fromFormat(dateString, format).isValid
    }

    static convertFormat(dateString: string, fromFormat: string, toFormat: string) {
        const date = DateTime.fromFormat(dateString, fromFormat)
        return date.toFormat(toFormat)
    }
    

}

    export const formatDate = (timestamp) => {

        if (!timestamp) {
            return ''; 
        }

        const date = new Date(timestamp);

        if (date.getFullYear() === 1000 && date.getMonth() === 0 && date.getDate() === 1) {
            return '';
        }

        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long'
        });
    };

export default DateUtils;