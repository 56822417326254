import { Card } from "../layout/Card"
import { Cards } from "../layout/Cards"


export interface DisasterResponseCardProps {
    globalState: GlobalState;
    blok: any;
}

export const DisasterResponseCards = ({ blok, globalState }: DisasterResponseCardProps) => {
    const disasterResponses = globalState.disasters;

    return (
        <Cards cardCount={globalState.disasters.length} style={{width: blok.width}}>
            {

                disasterResponses.map((disaster, index) => {

                    return (

                        <Card
                            imageSrc={`https://images.ppm.org/upload/disasterphotos/thumb/${disaster.url}.jpg?v=${disaster.photoVersion}`}
                            tagLine={""}
                            titleText={disaster.title}
                            subText={disaster.theLocationName}
                            key={index}
                            gridItem={{ cursor: "pointer" }}
                            disasterLink={disaster}
                            locationLinkType="standard"
                        > </Card>

                    )
                })
            }
        </Cards>
    )
}

export default DisasterResponseCards;
