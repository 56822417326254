import { Box, Container, Flex, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/legacy/image";
import { Dispatch, SetStateAction } from "react";
import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import DateUtils from '../../utils/dateUtils'


const DisasterResponseHero = ({ blok, globalState, setGlobalState, locationState, locale}: DynamicComponentProps<any>):JSX.Element => {
  // const ctx = useContext(CtxGlobal);
  const thisDisaster = globalState.thisDisaster;

  if (!thisDisaster) return null;

  return (
    <Box
      w={'full'}
      minH={{base: `${blok.minimumHeightMobile ?? '50vh'}`, lg: `${blok.minimumHeightDesktop ?? '50vh'}`}}
      height={'full'}
      {...storyblokEditable(blok)}
    >
      <Box
        w={'full'}
        minH={{base: `${blok.minimumHeightMobile ?? '50vh'}`, lg: `${blok.minimumHeightDesktop ?? '50vh'}`}}
        position="absolute"
        overflow={'hidden'}
        zIndex={-1}
      >
        <Box height="full">
          <Image
            src={`https://images.ppm.org/upload/disasterphotos/${thisDisaster.disasterInfo.url}.jpg?v=${thisDisaster.disasterInfo.photoVersion}`}
            alt={thisDisaster.disasterInfo.title}
            objectFit='cover'
            objectPosition={`center ${blok.imageVerticalAlign}`}
            layout="fill"

          />
        </Box>
      </Box>

      <Flex
        w={'full'}
        direction="column"
        minH={{base: `${blok.minimumHeightMobile ?? '50vh'}`, lg: `${blok.minimumHeightDesktop ?? '50vh'}`}}
        justifyContent={'center'}
        alignItems={'center'}
        px={{ base: 4, md: 8 }}
        bgGradient={'linear(to-r, blackAlpha.600, blackAlpha.600)'}
      >
        <Container maxWidth="container.xl">
          <SimpleGrid
            columns={{ base: 1, lg: 2}}
            gap={6}
            {...storyblokEditable(blok)}
            justifyContent="center"
            alignItems="center"
          >
            <GridItem my={{ base: 0, sm: 2 }} >
              <Text as="h1" color="white" textShadow={`2px 2px 2px black`}>
                PPM Disaster Response: <span style={{fontWeight:"normal"}}>{thisDisaster.disasterInfo.title}</span>
              </Text>
            </GridItem>
            <GridItem my={{ base: 0, sm: 2 }} justifyContent="center" alignItems="center">
              {blok.content.map((nestedBlok, index) => (
                <DynamicComponent blok={nestedBlok} key={index} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} locale={locale} />
              ))}
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </Box>
  )
}

export default DisasterResponseHero;