import { storyblokEditable } from "@storyblok/react";
import ButtonLink from "./ButtonLink";

interface Props {
  blok: any;
  globalState: GlobalState;
}

const ButtonFeaturedDisaster = ({ blok, globalState }: Props) => {
  const featuredDisaster = globalState.disasters.find(disaster => disaster.featured);

  if (!featuredDisaster) {
    return null;
  }  

  blok.link = {
    url: `/response/${featuredDisaster.url}`
  };

  if (featuredDisaster.icon) {
    blok.leftIcon = {
      type: 'fas',
      icon: featuredDisaster.icon
    };
  }

  if (blok.button) {
    blok.button.buttonText = featuredDisaster.title;
  } else {
    blok.button = {
      buttonText: featuredDisaster.title
    };
  }
  
  return (
    <ButtonLink blok={blok} {...storyblokEditable(blok)}></ButtonLink>
  );
}

export default ButtonFeaturedDisaster;
