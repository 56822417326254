import { FormControl, FormErrorMessage, FormLabel, GridItem, Input, SimpleGrid, Text, VisuallyHidden } from "@chakra-ui/react";
import { DeepRequired, FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { ChurchChampionFormProps } from "./ChurchChampionForm";
import ChurchChampionFormLabel from "./ChurchChampionFormLabel";
interface ChurchChampionTextFieldProps {
  showLabels: boolean;
  colorScheme: 'light' | 'dark';
  fieldName: "firstname" | "lastname" | "address1" | "address2" | 
    "city" | "state" | "zipcode" | "country" | "dob" | "phone" | "email" | 
    "guardianFirst" | "guardianLast" | "guardianPhone" | "isRegistered" | 
    "groupName" | "tripLocation" | "tripDate" | "isChurchChampion";
  fieldLabel: string;
  fieldType: string;
  isRequired: boolean;
  horizontalLayout: boolean;
  fieldSize: 'sm' | 'md' | 'lg' | 'xl';
  register: UseFormRegister<ChurchChampionFormProps>;
  errors: FieldErrorsImpl<DeepRequired<ChurchChampionFormProps>>;
  submitting: boolean;
}
const ChurchChampionTextField = (props: ChurchChampionTextFieldProps):JSX.Element => (
  <FormControl
    borderRadius={'lg'}
    mb={{ base: 4, lg: props.horizontalLayout ? 0 : 4 }}
    isInvalid={!!props.errors[props.fieldName]}
    isDisabled={props.submitting}
  >
    <SimpleGrid columns={{ base: 1, md: props.showLabels ? 4 : 3 }}>
      {
        props.showLabels &&
        <GridItem colSpan={1}>
          <ChurchChampionFormLabel
            forField={props.fieldName}
            label={props.fieldLabel}
            color={props.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
          />
        </GridItem>
      }
      <GridItem colSpan={3}>
        {
          !props.showLabels &&
          <VisuallyHidden>
            <FormLabel htmlFor={props.fieldName} color="white">{props.fieldLabel}</FormLabel>
          </VisuallyHidden>
        }
        <Input
          name={props.fieldName}
          type={props.fieldType}
          variant="outline"
          size={props.fieldSize}
          layerStyle={props.colorScheme === 'light' ? 'lightForm' : 'darkForm'}
          placeholder={props.fieldLabel}
          {...props.register(props.fieldName, { required: props.isRequired ? 'Required' : false })}
        />
        <FormErrorMessage>
          {props.errors[props.fieldName]?.message}
        </FormErrorMessage>
      </GridItem>
    </SimpleGrid>
  </FormControl>
)
export default ChurchChampionTextField;