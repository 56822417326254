import { Box } from "@chakra-ui/react";
import IntlTelInput from "intl-tel-input/react";
import { useState } from "react";
import "intl-tel-input/styles";

const phoneErrorMap = [
    "Invalid number",
    "Invalid country code",
    "Too short",
    "Too long",
    "Invalid number",
];

const PhoneNumberInput = ({ isValid, onChangeNumber, inputProps }: { isValid?: (valid: boolean) => void, onChangeNumber?: (number: string) => void, inputProps: object }): JSX.Element => {

    const [phoneNumberValid, setPhoneNumberValid] = useState<boolean | null>(null)
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null)
    const [phoneErrorCode, setPhoneErrorCode] = useState<number | null>(null)


    const handlePhoneNumberChange = (number: string | null) => {
        setPhoneNumber(number)
        if (onChangeNumber) {
            onChangeNumber(number)
        }
    }

    const handlePhoneNumberValid = (valid: boolean | null) => {
        setPhoneNumberValid(valid)
        if (isValid) {
            isValid(valid)
        }
    }

    console.log(inputProps)

    return (
    
    <Box {...inputProps}>
        <IntlTelInput
             onChangeNumber={handlePhoneNumberChange}
             onChangeValidity={handlePhoneNumberValid}
             inputProps={{
                style: {width: "100%"}
             }}
            initOptions={{
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.4.0/build/js/utils.js",
                initialCountry: "us",
                onlyCountries: ["us"],
                containerClass: "w-full"
            }}
        />
    </Box>
    )
}

export default PhoneNumberInput