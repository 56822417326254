// import AddThisScript from '../../scripts/AddThis'

import { Box } from "@chakra-ui/react";
import Script from "next/script";

interface AddThisProps {
  link?: {
    url: string;
    title: string;
  }
}

const AddThis = (props:AddThisProps):JSX.Element => {
  return (
    <Box>
      {
        props.link ? 
        <div className="addthis_inline_share_toolbox" data-url={`${props.link.url}`} data-title={`${props.link.title}`} /> :
        <div className="addthis_inline_share_toolbox" /> 
      }
      <Script strategy="lazyOnload" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-4d66df0941bfd35a" />
    </Box>
  );
}

export default AddThis;