import { LinkBox, LinkOverlay, Flex, Button, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import Image from "next/legacy/image";




const SinglePartnerInfoWindow = (props: SingleMapInfoProps<LandingPagePartner>) => {
    let photoUrl;
    const partner = props.data
  
    if (partner.photo) {
      photoUrl = partner.photo
    } else {
      photoUrl = `/images/praying-pelican-logo-globe.png`;
    }
  
    return <>
      <LinkBox
        bg="white"
        borderWidth="1px"
        boxShadow="lg"
        overflow="hidden"
        cursor={'pointer'}
        width="250px"
        rounded="xl"
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -200px)',
          cursor: 'pointer',
          zIndex: 1000
        }}
      >
        <Link href={`/365/partners/${partner.id}`} passHref legacyBehavior>
          <LinkOverlay
            sx={{
              '&:hover': {
                textDecoration: "none",
              }
            }}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              h="200px"
            >
              <Image
                src={photoUrl}
                alt={partner.pastorFirstName + " " + partner.pastorLastName}
                width={partner.photo ? '250' : '130'}
                height={'130'}
                objectFit={'cover'}
                objectPosition={'top'}
              />
              <Flex
                p="2"
                direction="column"
                justifyContent={'center'}
                flexGrow="1"
              >
                <Text as="h3" fontSize="sm" textAlign={'center'} fontWeight='bold' lineHeight={1}>
                  {partner.pastorFirstName + " " + partner.pastorLastName}
                </Text>
                <Text
                  as="p"
                  textAlign={'center'}
                  mb="0"

                >
                  <em>{partner.churchName}</em>
                </Text>
                
                <Button
                  size="2xs"
                  padding="1"
                  colorScheme="brand.green"
                  mt="1"
                  rightIcon={<FontAwesomeIcon icon="chevron-right" />}
                >View Profile</Button>
              </Flex>
            </Flex>
          </LinkOverlay>
        </Link>
      </LinkBox>
      <div className="bottom-arrow"></div>
    </>;
  }

  export default SinglePartnerInfoWindow