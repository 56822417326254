import { Box, Center } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colorToVar } from "../../utils/misc";

const CircleIcon = ({blok}):JSX.Element => {
  const iconColor = colorToVar(blok.iconColor, blok.iconSaturation);
  const backgroundColor = colorToVar(blok.backgroundColor, blok.backgroundSaturation);
  
  if (!blok.icon) return null;

  return (
    <Box 
      width="full"
      className={`fa-stack fa-${blok.iconSize}x`}
    >
      <FontAwesomeIcon 
        icon={['fas', 'circle']}
        color={backgroundColor}
        fixedWidth={true}
        className="fa-stack-2x"
      />
      <FontAwesomeIcon 
        icon={[blok.icon.type, blok.icon.icon]}
        color={iconColor}
        className="fa-stack-1x"
      />
    </Box>
  )
}

export default CircleIcon;