import { Container as ChakraContainer } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";

const Container = ({ blok, globalState, setGlobalState, locationState, ppm365, locale}: DynamicComponentProps<any>) => {
  const verticalAlignment = blok.verticalAlign === 'top' ? 'flex-start' : blok.verticalAlign === 'bottom' ? 'flex-end' : 'center';
  const horizontalAlignment = blok.horizontalAlign === 'left' ? 'flex-start' : blok.horizontalAlign === 'right' ? 'flex-end' : 'center';

  return (
    <ChakraContainer 
      {...storyblokEditable(blok)}
      maxW={blok.maxWidth} 
      marginTop={blok.marginTop} 
      marginBottom={blok.marginBottom} 
      paddingTop={blok.paddingTop}
      paddingBottom={blok.paddingBottom}
      px={{base: 5, xl: 0}}
      justifyContent={verticalAlignment}
      alignItems={horizontalAlignment}
      display="flex"
      flexDirection="column"
      id={blok.anchor}
    >
      {blok.content.map((nestedBlok, index) => (
        <DynamicComponent 
          key={index} 
          blok={nestedBlok} 
          globalState={globalState} 
          setGlobalState={setGlobalState} 
          locationState={locationState}
          ppm365={ppm365}
          locale={locale}
        />
      ))}
    </ChakraContainer>
  );
};

export default Container;
