import { Box } from "@chakra-ui/react"
import { storyblokEditable } from "@storyblok/react"
import { useContext } from "react"
import { createMapData } from "../../../utils/maps"
import { PartnerProfileContext } from "../../contexts/ppm365"
import PpmMap from "../../maps/PpmMap"

export interface PartnerMapProps {
    blok: any
}

const PartnerMap = (props: PartnerMapProps): JSX.Element => {

    const profile = useContext(PartnerProfileContext)
    const mapData = createMapData([{ ...profile, name: `` }])

    if (!props.blok.height) props.blok.height="100%"

    return (
        <Box
            {...storyblokEditable(props.blok)}
            borderRadius={"xl"}
            overflow={"hidden"}
            width={`${props.blok.width}%`} minHeight={"250px"} height={props.blok.height}>
            <PpmMap<Ppm365PartnerProfile> allowMarkerClick={false} mapData={mapData} />
        </Box>

    )
}

export default PartnerMap