import DynamicComponent, { DynamicComponentProps } from "../DynamicComponent";
import { SimpleGrid, GridItem } from '@chakra-ui/react'
import { storyblokEditable } from "@storyblok/react";

const Grid = ({ blok, globalState, setGlobalState, locationState, ppm365, locale }: DynamicComponentProps<any>) => (
  <SimpleGrid 
    columns={{base: 1, md: blok.columns.length}} 
    gap={6}
    {...storyblokEditable(blok)}
  >
    {blok.columns.map((nestedBlok, index) => (
      <GridItem key={index} my={{base: 0, sm: 2}} >
        <DynamicComponent 
          blok={nestedBlok} 
          globalState={globalState} 
          setGlobalState={setGlobalState} 
          locationState={locationState}
          ppm365={ppm365}
          locale={locale}
        />
      </GridItem>
    ))}
  </SimpleGrid>
);

export default Grid;
