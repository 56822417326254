import { Checkbox, FormControl, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { UseFormRegister } from "react-hook-form";
import { Main365SubscribeFormProps } from "./MainPpm365Subscribe";

interface LocationCheckboxesProps {
  heading: string;
  colorScheme: 'light' | 'dark';
  locations: StoryblokLocation[];
  register: UseFormRegister<Main365SubscribeFormProps>;
  submitting: boolean;
}

const Main365SubscribeFormLocationCheckboxes = (props:LocationCheckboxesProps):JSX.Element => {
  return (
    <>
      <Text
        color={props.colorScheme === 'light' ? 'brand.darkgray.600' : 'brand.green.600'}
        fontSize="sm"
        my="3"
        fontWeight={'bold'}
      >
        {props.heading}
      </Text>

      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        maxH="36"
        overflowY="auto"
        border="1px solid"
        borderColor={props.colorScheme === 'light' ? 'blackAlpha.500' : 'whiteAlpha.700'}
        backgroundColor={props.colorScheme === 'light' ? 'white' : 'brand.darkgray.600'}
        borderRadius="lg"
        w="full"
        mb="4"
        sx={{
          '&::-webkit-scrollbar': {
            width: '16px',
            borderRadius: 'lg',
            backgroundColor: `rgba(0, 0, 0, 0.2)`,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 'lg',
            backgroundColor: `rgba(0, 0, 0, 0.2)`,
          },
        }}
      >
        {
          props.locations
            .sort((a, b) => {
              const aNameWithoutThe = a.name.indexOf('The ') === 0 ? a.name.replace('The ', '') : a.name;
              const bNameWithoutThe = b.name.indexOf('The ') === 0 ? b.name.replace('The ', '') : b.name;

              return aNameWithoutThe.localeCompare(bNameWithoutThe);
            })
            .map((location, index) =>
              <GridItem
                key={index}
              >
                <FormControl isDisabled={props.submitting} textAlign='left'>
                  <Checkbox
                    m="2"
                    colorScheme={'brand.green'}
                    color={props.colorScheme === 'light' ? 'brand.darkgray.600' : 'white'}
                    value={location.rootsId}
                    {...props.register('locationArray', { required: false })}
                  >
                    {location.name}
                  </Checkbox>
                </FormControl>
              </GridItem>
            )
        }
      </SimpleGrid>
    </>
  )
}

export default Main365SubscribeFormLocationCheckboxes;