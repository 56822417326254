import React from "react";
import { Flex, Text as ChakraText } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colorToVar, convertColor } from "../../utils/misc";

const Text = ({ blok }) => {
  const justifyContent = blok.alignment === 'center' ? 'center' : blok.alignment === 'right' ? 'flex-end' : 'flex-start';
  const color = convertColor(blok.color, blok.saturation)
  return (
    <Flex 
      direction="row" 
      alignItems="center" 
      columnGap="2" 
      width="full"
      justifyContent={justifyContent}
      marginTop={blok.marginTop}
      marginBottom={blok.marginBottom}
      {...storyblokEditable(blok)} 
    >
      <ChakraText 
        color={color} 
        fontWeight={blok.fontWeight} 
        fontSize={blok.size} 
        as={blok.style} 
        textAlign={blok.alignment}
        textShadow={blok.textShadow > 0 ? `2px 2px ${blok.textShadow}px black` : 'none'}
      >
        {
          blok.leftIcon && blok.leftIcon.icon !== '' && 
          <FontAwesomeIcon 
            icon={[blok.leftIcon.type, blok.leftIcon.icon]} 
            color={blok.color}
            style={{ marginRight: '10px' }} 
          />
        }
        {blok.content}
        {
          blok.rightIcon && blok.rightIcon.icon !== '' &&
          <FontAwesomeIcon
            icon={[blok.rightIcon.type, blok.rightIcon.icon]} 
            color={blok.color}
            style={{ marginLeft: '10px' }}
          />
        }
      </ChakraText>
    </Flex>
  );
};

export default Text;
